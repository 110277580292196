<template>
  <v-container class="booking-container" style="font-family: lato, sans-serif">
    <!-- ---------------------- Step Header ---------------------- -->
    <div class="step-header">
      <div class="step-header-steps d-flex flex-row align-center mb-12">
        <!-- ------------ Step 1 ------------ -->
        <div class="d-flex flex-column">
          <div class="step-title font-weight-bold font-lato-italic mb-1 text-center" :class="step == 1 ? 'renedian-bright-orange--text' : 'white--text'">
            Step 1 of 3
          </div>
          <v-avatar v-if="step == 1">
            <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FTOUR%20selected.svg?alt=media&token=a2f3b3d0-4f2d-4cb3-bce4-01404a1134a6&_gl=1*frtlhp*_ga*MTkyNDAzNTA0My4xNjgxNjc3NDE2*_ga_CW55HF8NVT*MTY5ODg0OTg1Ny4xMDUuMS4xNjk4ODUwNDU3LjYwLjAuMA.."></v-img>
          </v-avatar>
          <v-avatar v-else color="#d86226">
            <v-icon large color="white">mdi-check</v-icon>
          </v-avatar>
          <div class="step-description mt-1 font-lato-bold" :class="step == 1 ? 'renedian-bright-orange--text' : 'grey--text'">
            <span class="step-description-inner">Tour<br>Details</span>
          </div>
        </div>
        <!-- ------------ Divider ------------ -->
        <div class="flex-1">
          <div class="d-flex flex-column">
            <div class="step-title-spacer"></div>
            <div class="d-flex align-center step-icon-spacer">
              <div class="step-divider"></div>
            </div>
            <div style="border: 1px solid white"></div>
          </div>
        </div>
        <!-- ------------ Step 2 ------------ -->
        <div class="d-flex flex-column">
          <div class="step-title font-weight-bold font-lato-italic mb-1 text-center" :class="step == 2 ? 'renedian-bright-orange--text' : 'white--text'">
            Step 2 of 3
          </div>
          <div>
            <v-avatar v-if="step == 1">
              <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FBOOKING.svg?alt=media&token=256e65fe-2473-469c-bd50-a2166309cc88&_gl=1*1c3j7v3*_ga*MTkyNDAzNTA0My4xNjgxNjc3NDE2*_ga_CW55HF8NVT*MTY5ODg0OTg1Ny4xMDUuMS4xNjk4ODUxODM1LjQ5LjAuMA.."></v-img>
            </v-avatar>
            <v-avatar v-if="step == 2">
              <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FBOOKING%20selected.svg?alt=media&token=31ebad4a-1e01-4024-8c2e-1938f980f362&_gl=1*12ikqck*_ga*MTkyNDAzNTA0My4xNjgxNjc3NDE2*_ga_CW55HF8NVT*MTY5ODg0OTg1Ny4xMDUuMS4xNjk4ODUxODI0LjYwLjAuMA.."></v-img>
            </v-avatar>
            <v-avatar v-if="step > 2" color="#d86226">
              <v-icon large color="white">mdi-check</v-icon>
            </v-avatar>
            <div class="step-description mt-1 font-lato-bold" :class="step == 2 ? 'renedian-bright-orange--text' : 'grey--text'">
              <span class="step-description-inner">Contact<br>Details</span>
            </div>
          </div>
        </div>
        <!-- ------------ Divider ------------ -->
        <div class="flex-1">
          <div class="d-flex flex-column">
            <div class="step-title-spacer"></div>
            <div class="d-flex align-center step-icon-spacer">
              <div class="step-divider"></div>
            </div>
            <div style="border: 1px solid white"></div>
          </div>
        </div>
        <!-- ------------ Step 3 ------------ -->
        <div class="d-flex flex-column">
          <div class="step-title font-weight-bold font-lato-italic mb-1 text-center" :class="step == 3 ? 'renedian-bright-orange--text' : 'white--text'">
            Step 3 of 3
          </div>
          <div>
            <v-avatar v-if="step < 4">
              <v-img v-if="step == 3" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FCONFIRM%20selected.svg?alt=media&token=0fc98829-2e20-459a-97ae-62eb33dae313"></v-img>
              <v-img v-else src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FCONFIRM.svg?alt=media&token=ce8a2d5a-2b62-4f2e-afec-ab2975df9136"></v-img>
            </v-avatar>
            <v-avatar v-if="step > 3" color="#d86226">
              <v-icon large color="white">mdi-check</v-icon>
            </v-avatar>
            <div class="step-description mt-1 font-lato-bold" :class="step == 3 ? 'renedian-bright-orange--text' : 'grey--text'">
              <span class="step-description-inner">Summary &<br>Deposit</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- ---------------------- Loading Indicator ---------------------- -->
    <v-progress-linear v-if="loading" indeterminate color="orange"></v-progress-linear>
    <!-- ---------------------- Steps ---------------------- -->
    <v-stepper v-if="!loading && !invalidTour" v-model="step" flat>
      <v-stepper-items>
        <!-- ---------------------- STEP 1: Tour Selection Step ---------------------- -->
        <v-stepper-content step="1" class="pa-0">
          <!-- <div class="r-title d-flex justify-center my-6">TOUR SELECTION</div> -->
          <div class="d-flex flex-column align-center">
            <!-- ------ WHICH TOUR are you interested in? ------ -->
            <template v-if="!tour || !id"><!-- Only show if a tour is not already selected -->
              <div class="d-flex flex-row mb-1">
                <div class="g-font renedian-red--text font-size-18 mr-2">SELECT YOUR TOUR</div>
                <!-- <div class="r-font renedian-burnt-orange--text">
                  Are You Interested In?
                </div> -->
              </div>
              <v-container class="d-flex tour-selection">
                <v-select v-model="selectedTour" :items="sortedTours" item-text="name" return-object filled></v-select>
              </v-container>
            </template>
            <!-- ------ WHEN would you like to go? ------ -->
            <template v-if="(!tour || !id) && selectedTour"><!-- Only show if a tour is selected-->
              <div class="d-flex flex-row mb-1">
                <div class="g-font renedian-red--text font-size-18 mr-2">SELECT YOUR DATE</div>
                <!-- <div class="r-font renedian-burnt-orange--text">
                  Would You Like To Go?
                </div> -->
              </div>
              <v-container class="d-flex tour-selection">
                <v-select v-model="selectedTourId" :items="selectedTour.dates" item-text="schedule" item-disabled="disabled" item-value="id" filled>
                  
                </v-select>
                <v-alert v-if="selectedTour.dates.length == 0" type="info" outlined>
                  There are no tours available for booking at this time.
                </v-alert>
              </v-container>
            </template>
          </div>
          <div v-if="!tour" class="d-flex flex-column align-center mt-4">
            <v-btn @click="inquiryDialog = true" text depressed color="#bd4426" dark style="width: 50%">
              I HAVE QUESTIONS
            </v-btn>
          </div>

          <!-- ------ Mobile Banner ------ -->
          <div v-if="tour" class="d-flex d-sm-none flex-column align-center mobile-banner">
            <img class="d-flex mobile-badge pa-6" :src="tour.template.images.badge">
            <div class="flex-1 d-flex flex-column justify-center">
              <h3 v-if="booking.excursion" class="text-center">
                {{ formatDateFull(tour.schedule.complete.start) }} -
                {{ formatDateFull(tour.schedule.complete.finish) }}
              </h3>
              <h3 v-else class="text-center">
                {{ formatDateFull(tour.schedule.primary.start) }} -
                {{ formatDateFull(tour.schedule.primary.finish) }}
              </h3>
              <div class="font-italic px-8 py-6" style="font-size: 14px">
                {{ tour.details.description }}
              </div>
            </div>
          </div>

          <!-- ------ Tablet Banner ------ -->
          <div v-if="tour" class="tablet-banner d-none d-sm-flex d-md-none flex-row">
            <img class="tablet-badge d-none d-sm-flex" :src="tour.template.images.badge">
            <div class="flex-1 d-flex flex-column justify-center">
              <h3 v-if="booking.excursion" class="text-center">
                {{ formatDateFull(tour.schedule.complete.start) }} -
                {{ formatDateFull(tour.schedule.complete.finish) }}
              </h3>
              <h3 v-else class="text-center">
                {{ formatDateFull(tour.schedule.primary.start) }} -
                {{ formatDateFull(tour.schedule.primary.finish) }}
              </h3>
              <div class="font-italic py-4" style="font-size: 14px">
                {{ tour.details.description }}
              </div>
            </div>
          </div>

          <!-- ------ Tour Specifications ------ -->
          <v-row v-if="tour" class="my-6 d-flex d-sm-none" style="margin: 0 60px" justify="center" align="center">
            <!-- Duration -->
            <v-col cols="6" sm="2" md="0" class="duration-container d-flex flex-column justify-center align-center">
              <div class="duration-value" style="margin-top: 10px">{{ tour.details.duration }}</div>
              <img style="width: 80px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FDAYS.svg?alt=media&token=f1f1ee7e-8cb0-4c54-b787-446ea12a177a" />
            </v-col>
            <!-- Distance -->
            <v-col cols="6" sm="2" md="0" class="d-flex flex-column justify-center align-center">
              <img style="width: 80px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FDistance%20icon.svg?alt=media&token=52f8316b-26de-4916-ac89-93a9e7a405c1" />
              <h5>{{ tour.details.distance }} km</h5>
              <h5>{{ tour.details.distance | miles }} mi</h5>
            </v-col>
            <!-- Paved -->
            <v-col cols="6" sm="2" md="0" class="d-flex flex-column justify-center align-center">
              <img style="width: 70px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FPAVED.svg?alt=media&token=e400add1-41a4-4089-9ee7-a726b813b626" />
              <div class="paved-text r-font">{{ 100 - tour.details.difficulty.gravel }}%</div>
            </v-col>
            <!-- Gravel -->
            <v-col cols="6" sm="2" md="0" class="d-flex flex-column justify-center align-center">
              <img style="width: 70px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FGRAVEL.svg?alt=media&token=87ad5d91-32d9-4b7e-89df-68628e1de9de" />
              <div class="gravel-text r-font">{{ tour.details.difficulty.gravel }}%</div>
            </v-col>
            <!-- Difficulty -->
            <v-col cols="6" sm="2" md="0" class="d-flex flex-column justify-center align-center">
              <img v-if="tour.details.difficulty.gravel <= 10" style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-1.svg?alt=media&token=034bc977-ffe9-47a7-8570-2b5896c65809" />
              <img v-else-if="tour.details.difficulty.gravel <= 20" style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-2.svg?alt=media&token=2cdd8365-a3f3-4d9a-a2ce-3161b1cba582" />
              <img v-else style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-3.svg?alt=media&token=37c27ab8-42cd-4994-9d9d-5b317b0bb017" />
            </v-col>
          </v-row>

          <!-- ------ Tour Preferences ------ -->
          <div v-if="tour" class="tour-preference-container d-flex flex-column flex-md-row">
            <!-- ------------ Column: Tour Details ------------ -->
            <div class="flex-1">              
              <div class="pa-12">
                <!-- ------------ HOW MANY will be joining us? ------------ -->
                <template v-if="tour">
                  <div class="d-flex flex-row align-center">
                    <r-icon class="mx-2" icon="guests"></r-icon>
                    <div class="r-font renedian-burnt-orange--text">
                      HOW MANY will be joining us?
                    </div>
                  </div>
                  <v-container class="d-flex">
                    <div class="d-flex flex-column">
                      <div @click="changeGuests(1)" class="d-flex flex-row align-center py-1">
                        <v-btn v-if="booking.guests.length == 1" icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                        <v-btn v-else icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                        <r-icon class="ml-1 mr-3" icon="guests-solo"></r-icon>
                        <div class="font-weight-bold text-uppercase" style="color: #0009">one guest</div>
                      </div>
                      <div @click="changeGuests(2)" class="d-flex flex-row align-center py-1">
                        <v-btn v-if="booking.guests.length == 2" icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                        <v-btn v-else icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                        <r-icon class="ml-1" icon="guests-solo"></r-icon>
                        <r-icon class="mr-3" icon="guests-solo" style="margin-left: -8px"></r-icon>
                        <div class="font-weight-bold text-uppercase" style="color: #0009">two guests</div>
                      </div>
                    </div>
                  </v-container>
                </template>

                <div v-if="tour" class="orange-container">
                  <div v-if="booking.guests.length == 2" class="g-font font-size-18 px-6 py-3 guest-title">GUEST 1</div>

                  <!-- ------ Guest #1: Riding Preference ------ -->
                  <template>
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="guests-solo"></r-icon>
                      <div class="r-font renedian-burnt-orange--text">
                        I will be a:
                      </div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="booking.guests[0].preferences.riding" @change="ridingPreferenceChanged()" class="mt-0 pt-0" hide-details>
                        <v-radio value="bike" class="my-2 d-flex align-center">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="ml-1 mr-3" icon="bike-grey"></r-icon>
                              MOTORCYCLE RIDER <span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.base | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio v-if="tour.details.supportVan" value="van" class="my-2 d-flex align-center">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="ml-1 mr-3" icon="van-grey"></r-icon>
                              PASSENGER IN SUPPORT VAN <span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.passenger | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </template>

                  <div class="orange-divider"></div>
                  
                  <!-- ------ Guest #1: Accommodation ------ -->
                  <template v-if="booking.guests.length == 1">
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="bed-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">ACCOMODATION CHOICE:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="booking.guests[0].preferences.room" @change="accomodationPreferenceChanged()" class="mt-0 pt-0" hide-details>
                        <v-radio value="shared" class="mt-2 mb-0">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="sharing-grey"></r-icon>
                              I WILL SHARE A ROOM<span class="font-weight-bold font-italic text-caption ml-2">(Included)</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio-group v-if="booking.guests[0].preferences.room == 'shared'" v-model="booking.guests[0].preferences.roommate.type" class="ml-6 pt-0" @change="accomodationPreferenceChanged()" hide-details>
                          <v-radio value="unknown" label="FIND ME A ROOMMATE" class="mb-2"></v-radio>
                          <v-radio value="known" label="I KNOW MY ROOMMATE" class="mt-2"></v-radio>
                          <v-text-field v-if="booking.guests[0].preferences.roommate.type == 'known'" v-model="booking.guests[0].preferences.roommate.name" class="mt-2" filled hide-details label="Full name of roommate"></v-text-field>
                        </v-radio-group>
                        <v-radio value="solo" class="mt-4">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="solo-grey"></r-icon>
                              I PREFER A SOLO ROOM<span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.solo | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <v-radio-group v-if="booking.guests.length == 2" v-model="booking.guests[0].preferences.room" @change="accomodationPreferenceChanged()" class="mt-0 pt-0" hide-details>
                        <v-radio value="shared" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="sharing-grey"></r-icon>
                              WE WILL BE SHARING A ROOM<span class="font-weight-bold font-italic text-caption ml-2">(Included)</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="solo" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="solo-grey"></r-icon>
                              WE WOULD LIKE SEPARATE ROOMS<span class="font-weight-bold font-italic text-caption ml-2">({{ (2 * tour.details.pricing.solo) | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </template>

                  <div class="orange-divider" v-if="booking.guests.length == 1"></div>

                  <!-- ------ Guest #1: Vehicle Selection ------ -->
                  <template v-if="tour && booking.guests[0].preferences.riding == 'bike' && computedVehicles.length">
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="bike-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">MOTORCYCLE CHOICE:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="selectedVehicles[0]" @change="vehiclePreferenceChanged(0)" class="mt-0 pt-0" hide-details>
                        <div v-for="(vehicle, vehicleIndex) in computedVehicles" :key="vehicle.id">
                          <v-radio :value="vehicle" return-object class="my-2">
                            <template v-slot:label>
                              <div class="d-flex flex-row align-center font-weight-bold">
                                <v-avatar class="mr-2"><img :src="vehicle.thumbnail"></v-avatar>
                                {{ vehicle.make }} {{ vehicle.model }}
                                <span v-if="vehicle.price == 0 && vehicleIndex == 0" class="font-weight-bold font-italic text-caption ml-2">(Included)</span>
                                <span v-else-if="vehicle.price < 0" class="font-weight-bold font-italic text-caption ml-2 green--text">({{ vehicle.price | currency }})</span>
                                <span v-else class="font-weight-bold font-italic text-caption ml-2">({{ vehicle.price | currency }})</span>
                              </div>
                            </template>
                          </v-radio>
                          <div v-if="vehicle.options.length && booking.guests[0].vehicle.id == vehicle.id"  class="ml-6">
                            <!-- <pre>{{ booking.guests[0].vehicle }}</pre> -->
                            <div v-for="(option, optionIndex) in booking.guests[0].vehicle.options" :key="'option' + optionIndex" class="mb-3">
                              <v-checkbox v-model="option.selected" hide-details @change="calculateCharges()">
                                <template v-slot:label>
                                  {{ option.name }}
                                  <span class="font-weight-bold font-italic text-caption ml-2">({{ option.price | currency }})</span>
                                </template>
                              </v-checkbox>
                            </div>
                          </div>
                        </div>
                      </v-radio-group>
                    </div>
                  </template>
                  <template v-if="tour && booking.guests[0].preferences.riding == 'bike' && computedVehicles.length == 0">
                    <v-alert type="info" outlined>
                      There are no motorcycles available for booking at this time.
                    </v-alert>
                  </template>

                  <div class="orange-divider" v-if="tour && booking.guests[0].preferences.riding == 'bike' && computedVehicles.length"></div>
                  
                  <!-- Guest 1 Returning Rider -->
                  <template v-if="tour">
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <v-icon class="mx-2" color="#bd4427">mdi-earth</v-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">ALUMNI RIDER:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <div class="d-flex flex-row flex-sm-column flex-md-row flex-md-row align-center justify-center font-weight-bold text-uppercase renedian-grey--text">
                        Are you a returning Renedian rider?
                        <div @click="booking.guests[0].returning = 1" class="d-flex justify-center align-center py-1 mx-2">
                          <v-btn v-if="booking.guests[0].returning" icon>
                            <v-icon color="#ef6c00">mdi-radiobox-marked</v-icon>
                          </v-btn>
                          <v-btn v-else icon>
                            <v-icon>mdi-radiobox-blank</v-icon>
                          </v-btn>
                          <div class="font-weight-bold text-uppercase" style="color: #0009">Yes</div>
                        </div>
                        <div @click="booking.guests[0].returning = 0" class="d-flex justify-center align-center py-1 mx-2">
                          <v-btn v-if="!booking.guests[0].returning" icon>
                            <v-icon color="#ef6c00">mdi-radiobox-marked</v-icon>
                          </v-btn>
                          <v-btn v-else icon>
                            <v-icon>mdi-radiobox-blank</v-icon>
                          </v-btn>
                          <div class="font-weight-bold text-uppercase" style="color: #0009">No</div>
                        </div>
                      </div>
                      <div v-if="booking.guests[0].returning" class="text-center font-italic renedian-grey--text">
                        To correctly apply you Alumni Discount, please tell us how many tours you have been on with us. This "{{ booking.tour.name }}" tour will be your:
                        <v-container>
                          <v-radio-group v-model="booking.guests[0].returning" @change="calculateCharges()" class="mt-0 pt-0" hide-details>
                            <v-radio value="1" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  First tour - no discount
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="2" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Second tour with Renedian: -CAD$400
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="3" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Third tour with Renedian: -CAD$800
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="4" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Fourth tour with Renedian: -CAD$1,200
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="5" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Fifth tour with Renedian: -CAD$1,600
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-container>
                      </div>
                    </div>
                  </template>
                </div><!-- Orange Container-->

                <!-- ------ Guest #2: Riding Preference ------ -->
                <div v-if="tour && booking.guests.length == 2" class="orange-container mt-12">
                  <div class="g-font font-size-18 px-6 py-3 guest-title">GUEST 2</div>

                  <!-- ------ Guest #1: Riding Preference ------ -->
                  <template>
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="guests-solo"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">I WILL BE A:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="booking.guests[1].preferences.riding" @change="ridingPreferenceChanged()" class="mt-0 pt-0" hide-details>
                        <v-radio value="bike" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="ml-1 mr-3" icon="bike-grey"></r-icon>
                              MOTORCYCLE RIDER<span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.base | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio v-if="booking.guests[0].preferences.riding == 'bike'" value="passenger" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="ml-1 mr-3" icon="passenger-grey"></r-icon>
                              PASSENGER ON MOTORCYCLE<span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.passenger | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio v-if="tour.details.supportVan" value="van" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="van-grey"></r-icon>
                              PASSENGER IN SUPPORT VAN<span class="font-weight-bold font-italic text-caption ml-2">({{ tour.details.pricing.passenger | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </template>

                  <div class="orange-divider"></div>

                  <!-- ------ Guest #2: Accommodation ------ -->
                  <template>
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="bed-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">ACCOMODATION CHOICE:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="booking.guests[0].preferences.room" @change="accomodationPreferenceChanged()" hide-details>
                        <v-radio value="shared" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="sharing-grey"></r-icon>
                              WE WILL BE SHARING A ROOM
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="solo" class="my-2">
                          <template v-slot:label>
                            <div class="d-flex flex-row align-center font-weight-bold">
                              <r-icon class="mr-2" icon="solo-grey"></r-icon>
                              WE WOULD LIKE SEPARATE ROOMS<span class="font-weight-bold font-italic text-caption ml-2">({{ (2 * tour.details.pricing.solo) | currency }})</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </template>

                  <div class="orange-divider"></div>

                  <!-- ------ Guest #2: Vehicle ------ -->
                  <template v-if="booking.guests[1].preferences.riding == 'bike'">
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="bike-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">MOTORCYCLE CHOICE:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-radio-group v-model="selectedVehicles[1]" @change="vehiclePreferenceChanged(1)" class="mt-0 pt-0" hide-details>
                        <div v-for="(vehicle, vehicleIndex) in computedVehicles" :key="vehicle.id">
                          <v-radio :value="vehicle" return-object class="my-2">
                            <template v-slot:label>
                              <div class="d-flex flex-row align-center font-weight bold">
                                <v-avatar class="mr-2"><img :src="vehicle.thumbnail"></v-avatar>
                                {{ vehicle.make }} {{ vehicle.model }}
                                <span v-if="vehicle.price == 0 && vehicleIndex == 0" class="font-weight-bold font-italic text-caption ml-2">(Included)</span>
                                <span v-else-if="vehicle.price < 0" class="font-weight-bold font-italic text-caption ml-2 green--text">({{ vehicle.price | currency }})</span>
                                <span v-else class="font-weight-bold font-italic text-caption ml-2">({{ vehicle.price | currency }})</span>
                              </div>
                            </template>
                          </v-radio>
                          <div v-if="vehicle.options.length && booking.guests[1].vehicle.id == vehicle.id"  class="ml-6">
                            <div v-for="(option, optionIndex) in booking.guests[1].vehicle.options" :key="'option' + optionIndex" class="mb-3">
                              <v-checkbox v-model="option.selected" hide-details @change="calculateCharges()">
                                <template v-slot:label>
                                  {{ option.name }}
                                  <span v-if="option.price == 0" class="font-weight-bold font-italic text-caption ml-2">(Included)</span>
                                  <span v-else class="font-weight-bold font-italic text-caption ml-2">({{ option.price | currency }})</span>
                                </template>
                              </v-checkbox>
                            </div>
                          </div>
                        </div>
                      </v-radio-group>
                    </div>
                  </template>
                  <template v-if="tour && booking.guests[1].preferences.riding == 'bike' && computedVehicles.length == 0">
                    <v-alert type="info" outlined>
                      There are no motorcycles available for booking at this time.
                    </v-alert>
                  </template>

                  <div class="orange-divider" v-if="booking.guests[1].preferences.riding == 'bike'"></div>

                  <!-- Guest 2 Returning Rider -->
                  <template>
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="bike-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">ALUMNI RIDER:</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <div class="d-flex flex-column flex-md-row align-center justify-center font-weight-bold text-uppercase renedian-grey--text">
                        Are you a returning Renedian rider?
                        <div @click="booking.guests[1].returning = 1" class="d-flex justify-center align-center py-1 mx-2">
                          <v-btn v-if="booking.guests[1].returning" icon>
                            <v-icon color="#ef6c00">mdi-radiobox-marked</v-icon>
                          </v-btn>
                          <v-btn v-else icon>
                            <v-icon>mdi-radiobox-blank</v-icon>
                          </v-btn>
                          <div class="font-weight-bold text-uppercase" style="color: #0009">Yes</div>
                        </div>
                        <div @click="booking.guests[1].returning = 0" class="d-flex justify-center align-center py-1 mx-2">
                          <v-btn v-if="!booking.guests[1].returning" icon>
                            <v-icon color="#ef6c00">mdi-radiobox-marked</v-icon>
                          </v-btn>
                          <v-btn v-else icon>
                            <v-icon>mdi-radiobox-blank</v-icon>
                          </v-btn>
                          <div class="font-weight-bold text-uppercase" style="color: #0009">No</div>
                        </div>
                      </div>
                      <div v-if="booking.guests[1].returning" class="text-center font-italic renedian-grey--text">
                        To correctly apply you Alumni Discount, please tell us how many tours you have been on with us. This "{{ booking.tour.name }}" tour will be your:
                        <v-container>
                          <v-radio-group v-model="booking.guests[1].returning" @change="calculateCharges()" class="mt-0 pt-0" hide-details>
                            <v-radio value="1" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  First tour - no discount
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="2" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Second tour with Renedian: -CAD$400
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="3" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Third tour with Renedian: -CAD$800
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="4" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Fourth tour with Renedian: -CAD$1,200
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="5" class="my-2">
                              <template v-slot:label>
                                <div class="d-flex flex-row font-weight-bold">
                                  Fifth tour with Renedian: -CAD$1,600
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-container>
                      </div>
                    </div>
                  </template>
                </div>

                <!-- ------------ Pre-Trip Excursion ------------ -->
                <div  class="orange-container mt-12" v-if="tour && tour.excursion.available">
                  <!-- <div class="g-font font-size-18 px-6 py-3 guest-title">Pre-Trip Bus Excursion</div> -->
                  <template>
                    <!-- ------------ Section Heading ------------ -->
                    <div class="d-flex flex-row align-center pt-6 px-3">
                      <r-icon class="mx-2" icon="excursion-red"></r-icon>
                      <div class="r-font renedian-burnt-orange--text mr-2">Pre-Trip Bus Excursion</div>
                    </div>
                    <!-- ------------ Section Options ------------ -->
                    <div class="d-flex flex-column pa-6">
                      <v-container class="d-flex flex-column">
                        <h2>{{ tour.excursion.description.name }}</h2>
                        {{ tour.excursion.description.short }}
                        <v-btn color="orange" class="mt-4" outlined small @click="excursionDialog = true"><v-icon left>mdi-info-circle</v-icon>Learn More</v-btn>
                      </v-container>
                      <v-container>
                        <div class="d-flex flex-column">
                          <div v-if="booking.excursion == true" class="d-flex flex-row align-center py-1" @click="booking.excursion = false; calculateCharges();">
                            <v-btn icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                            <div class="font-weight-bold text-uppercase" style="color: #0009">
                              ADD THIS EXCURSION
                              <span v-if="booking.guests.length == 1 && booking.guests[0].preferences.room == 'shared'" class="font-weight-bold font-italic text-caption ml-2">({{ tour.excursion.pricing.base | currency }})</span>
                              <span v-if="booking.guests.length == 1 && booking.guests[0].preferences.room == 'solo'" class="font-weight-bold font-italic text-caption ml-2">({{ Number(tour.excursion.pricing.base) + Number(tour.excursion.pricing.solo) | currency }} with solo room)</span>
                              <span v-if="booking.guests.length == 2 && booking.guests[0].preferences.room == 'shared'" class="font-weight-bold font-italic text-caption ml-2">({{ 2 * Number(tour.excursion.pricing.base) | currency }} with 2 people)</span>
                              <span v-if="booking.guests.length == 2 && booking.guests[0].preferences.room == 'solo'" class="font-weight-bold font-italic text-caption ml-2">({{ 2 * (Number(tour.excursion.pricing.base) + Number(tour.excursion.pricing.solo)) | currency }} with 2 people in separate rooms)</span>
                            </div>
                          </div>
                          <div v-else class="d-flex flex-row align-center py-1" @click="booking.excursion = true; calculateCharges();">
                            <v-btn icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                            <div class="font-weight-bold text-uppercase" style="color: #0009">ADD THIS EXCURSION
                              <span v-if="booking.guests.length == 1 && booking.guests[0].preferences.room == 'shared'" class="font-weight-bold font-italic text-caption ml-2">({{ tour.excursion.pricing.base | currency }})</span>
                              <span v-if="booking.guests.length == 1 && booking.guests[0].preferences.room == 'solo'" class="font-weight-bold font-italic text-caption ml-2">({{ Number(tour.excursion.pricing.base) + Number(tour.excursion.pricing.solo) | currency }} with solo room)</span>
                              <span v-if="booking.guests.length == 2 && booking.guests[0].preferences.room == 'shared'" class="font-weight-bold font-italic text-caption ml-2">({{ 2 * Number(tour.excursion.pricing.base) | currency }} with 2 people)</span>
                              <span v-if="booking.guests.length == 2 && booking.guests[0].preferences.room == 'solo'" class="font-weight-bold font-italic text-caption ml-2">({{ 2 * (Number(tour.excursion.pricing.base) + Number(tour.excursion.pricing.solo)) | currency }} with 2 people in separate rooms)</span>
                            </div>
                          </div>
                          <div v-if="booking.excursion == false" class="d-flex flex-row align-center py-1" @click="booking.excursion = true; calculateCharges();">
                            <v-btn icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                            <div class="font-weight-bold text-uppercase" style="color: #0009">NO EXCURSION</div>
                          </div>
                          <div v-else class="d-flex flex-row align-center py-1" @click="booking.excursion = false; calculateCharges();">
                            <v-btn icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                            <div class="font-weight-bold text-uppercase" style="color: #0009">NO EXCURSION</div>
                          </div>
                        </div>
                      </v-container>
                    </div>
                  </template>                  
                </div>

                <!-- ------------ I'm Ready to Ride ------------ -->
                <div v-if="tour" class="d-flex flex-column justify-center mt-6">
                  <div class="d-flex flex-row justify-center align-center pa-3">
                    <!-- <div class="renedian-burnt-orange--text font-weight-bold mb-2" style="font-weight: 1.2rem">
                      {{ booking.guests.length == 1 ? "I'M" : "WE'RE" }} READY TO RIDE
                    </div> -->
                    <v-btn tile disabled class="mr-2">
                      PREVIOUS STEP
                    </v-btn>
                    <v-btn depressed tile color="#f26522" dark @click="step = 2" class="ml-2">
                      NEXT STEP
                    </v-btn>
                  </div>
                  <div class="d-flex flex-column align-center mt-4">
                    <v-btn @click="inquiryDialog = true" text depressed color="#bd4426" dark style="width: 50%">
                      I HAVE QUESTIONS
                    </v-btn>
                  </div>
                </div>
              </div><!-- Padding -->
            </div><!-- Column -->
            <!-- ------------ Column: Rip ------------ -->
            <div class="balance-summary-tear-big d-none d-md-flex d-lg-none"></div>
            <div class="balance-summary-tear d-none d-lg-flex"></div>
            <!-- ------------ Column: Balance Summary ------------ -->
            <div class="balance-summary-container d-none d-md-flex flex-column pl-4">
              <template v-if="tour">
                <!-- ------------ Tour Badge ------------ -->
                <div class="banner-container mt-8 d-flex flex-column justify-end" :style="{ 'background-image': 'url(' + tour.template.images.banner + ')' }">
                  <img class="banner-badge" :src="tour.template.images.badge">
                </div>
                <!-- ------------ Tour Dates ------------ -->
                <div class="d-flex flex-column pt-4 pr-7 pl-2" style="margin-top: -30px">
                  <h3 v-if="booking.excursion" class="text-center">
                    {{ formatDateFull(tour.schedule.complete.start) }} -
                    {{ formatDateFull(tour.schedule.complete.finish) }}
                  </h3>
                  <h3 v-else class="text-center">
                    {{ formatDateFull(tour.schedule.primary.start) }} -
                    {{ formatDateFull(tour.schedule.primary.finish) }}
                  </h3>
                  <div class="font-italic py-4" style="font-size: 14px">
                    {{ tour.details.description }}
                  </div>
                  <!-- ------------ Tour Details ------------ -->  
                  <div class="d-flex flex-row mt-4">
                    <!-- Duration -->
                    <div class="duration-container flex-1 d-flex justify-center">
                      <div class="duration-value mt-1">{{ tour.details.duration }}</div>
                      <img style="width: 80px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FDAYS.svg?alt=media&token=f1f1ee7e-8cb0-4c54-b787-446ea12a177a">
                    </div>
                    <!-- Distance -->
                    <div class="flex-1 d-flex flex-column justify-center align-center">
                      <img style="width: 100px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FDistance%20icon.svg?alt=media&token=52f8316b-26de-4916-ac89-93a9e7a405c1">
                      <div class="subtitle-1 font-weight-bold">{{ tour.details.distance }} km</div>
                      <div class="subtitle-1 font-weight-bold">{{ tour.details.distance | miles }} mi</div>
                    </div>
                  </div>
                  <!-- ------------ Roads ------------ -->
                  <div class="roads d-flex flex-row mt-6">
                    <!-- Paved -->
                    <div class="flex-1 d-flex flex-column align-center justify-center">
                      <img style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FPAVED.svg?alt=media&token=e400add1-41a4-4089-9ee7-a726b813b626">
                      <div class="paved-text r-font">{{ 100 - tour.details.difficulty.gravel }}%</div>
                    </div>
                    <!-- Gravel -->
                    <div class="flex-1 d-flex flex-column align-center justify-center">
                      <img style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FGRAVEL.svg?alt=media&token=87ad5d91-32d9-4b7e-89df-68628e1de9de">
                      <div class="gravel-text r-font">{{ tour.details.difficulty.gravel }}%</div>
                    </div>
                  </div>
                  <!-- Difficulty -->
                  <div class="d-flex flex-column mt-6">
                    <!-- Meter -->
                    <div class="d-flex justify-center">
                      <img v-if="tour.details.difficulty.rating == 1" style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-1.svg?alt=media&token=034bc977-ffe9-47a7-8570-2b5896c65809">  
                      <img v-if="tour.details.difficulty.rating == 2" style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-2.svg?alt=media&token=2cdd8365-a3f3-4d9a-a2ce-3161b1cba582">
                      <img v-if="tour.details.difficulty.rating == 3" style="width: 90px" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ficons%2FSKILL-LEVEL-3.svg?alt=media&token=37c27ab8-42cd-4994-9d9d-5b317b0bb017">
                    </div>
                    <!-- Description -->
                    <div class="d-flex flex-column align-center justify-center mt-3">
                      <div v-if="tour.details.difficulty.rating == 1" class="difficulty-title renedian-burnt-orange--text font-weight-bold">BASIC</div>
                      <div v-if="tour.details.difficulty.rating == 2" class="difficulty-title renedian-burnt-orange--text font-weight-bold">INTERMEDIATE</div>
                      <div v-if="tour.details.difficulty.rating == 3" class="difficulty-title renedian-burnt-orange--text font-weight-bold">ADVANCED</div>
                      <div class="text-caption mt-2">
                        {{ tour.details.difficulty.description }}
                      </div>
                    </div>
                  </div>
                  <!-- ------------ Summary ------------ -->
                  <div class="d-flex flex-column mt-12">
                    <h3 class="renedian-burnt-orange--text text-right text-uppercase">Your Trip Summary</h3>
                    <div class="grey-bar mt-2 mb-8"></div>
                    <div v-for="(charge, index) in booking.charges" :key="'charge' + index">
                      <div v-if="charge.items.length > 0" class="charge mb-6">
                        <div class="charge-name text-uppercase font-weight-bold">{{ charge.name }}</div>
                        <div v-for="(item, itemIndex) in charge.items" :key="'item' + index + '-' + itemIndex" :class="item.type == 'option' ? 'charge-option': ''">
                          <div v-if="charge.type == 'vehicle' && item.amount > 0" class="charge-description text-uppercase font-italic mt-2">{{ item.name }} (upgrade)</div>
                          <div v-else-if="charge.type == 'vehicle' && item.amount < 0" class="charge-description text-uppercase font-italic mt-2">{{ item.name }} (credit)</div>
                          <div v-else class="charge-description text-uppercase font-italic mt-2">{{ item.name }}</div>
                          
                          <div v-if="item.quantity == 1 && item.amount > 0" class="charge-price font-weight-bold renedian-red--text">{{ item.amount | currency }}</div>
                          <div v-else-if="item.quantity > 1 && item.amount > 0" class="charge-price font-weight-bold renedian-red--text">{{ item.quantity }} x {{ item.amount | currency }}</div>
                          <div v-else-if="item.amount < 0" class="charge-price font-weight-bold green--text">{{ item.amount | currency }}</div>
                          <div v-else-if="item.amount == 0" class="charge-price font-weight-bold renedian-red--text">Included</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Price Tag ------------ -->
                <div class="price-tag d-flex flex-row justify-end">
                    <div class="price-tag-tab"></div>
                    <div class="price-tag-body d-flex flex-column">
                      <div class="price-tag-subtotal">Subtotal</div>
                      <div class="price-tag-total">{{ computedSubtotal | currencySimple }}</div>
                    </div>
                  </div>
              </template>
            </div>
            <!-- ------ Mobile Summary ------ -->
            <div class="mobile-summary d-flex d-md-none flex-column">
              <div class="mobile-summary-tear"></div>
              <div class="renedian-beige px-12">
                <!-- Total -->
                <div class="total renedian-red--text text-decoration-underline font-weight-bold mt-6">
                  Subtotal: {{ computedSubtotal | currencyRaw }}
                </div>
                <!-- Toggle -->
                <div class="d-flex justify-center mt-2 mb-6">
                  <v-btn v-if="pricingDetails" text @click="pricingDetails = !pricingDetails">
                    <v-icon left color="orange">mdi-chevron-up</v-icon>Hide Pricing Details
                  </v-btn>
                  <v-btn v-else text @click="pricingDetails = !pricingDetails">
                    <v-icon left color="orange">mdi-chevron-down</v-icon>Expand Pricing Details
                  </v-btn>
                </div>
                <!-- Charges -->
                <div v-if="pricingDetails" class="my-12">
                  <div v-for="(charge, index) in booking.charges" :key="'charge' + index">
                    <div v-if="charge.items.length > 0" class="charge mb-6">
                      <div class="charge-name text-uppercase font-weight-bold">{{ charge.name }}</div>
                      <div v-for="(item, itemIndex) in charge.items" :key="'item' + index + '-' + itemIndex" :class="item.type == 'option' ? 'charge-option': ''">
                        <div v-if="charge.type == 'vehicle' && item.amount > 0" class="charge-description text-uppercase font-italic mt-2">{{ item.name }} (upgrade)</div>
                        <div v-else-if="charge.type == 'vehicle' && item.amount < 0" class="charge-description text-uppercase font-italic mt-2">{{ item.name }} (credit)</div>
                        <div v-else class="charge-description text-uppercase font-italic mt-2">{{ item.name }}</div>
                          
                        <div v-if="item.quantity == 1 && item.amount > 0" class="charge-price font-weight-bold renedian-red--text">{{ item.amount | currency }}</div>
                        <div v-else-if="item.quantity > 1 && item.amount > 0" class="charge-price font-weight-bold renedian-red--text">{{ item.quantity }} x {{ item.amount | currency }}</div>
                        <div v-else-if="item.amount < 0" class="charge-price font-weight-bold green--text">{{ item.amount | currency }}</div>
                        <div v-else-if="item.amount == 0" class="charge-price font-weight-bold renedian-red--text">Included</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>    
        </v-stepper-content>
        <!-- ---------------------- STEP 2: Booking & Summary ---------------------- -->
        <v-stepper-content step="2" v-if="tour" class="pa-0 text-center">
          <div class="g-font renedian-red--text mb-6 font-size-22">Contact Details</div>
          <!-- ------------ Contact Information ------------ -->
          <template>
            <v-form ref="form">
              <!-- Contact Information Header -->
              <div class="d-flex flex-row align-center mb-6">
                <div class="the-thin-grey-line flex-1 mr-2"></div>
                <div class="renedian-burnt-orange--text font-weight-bold font-size-14 text-uppercase">
                  Contact Information
                </div>
                <div class="the-thin-grey-line flex-1 ml-2"></div>
              </div>

              <!-- Guest 1 -->
              <div v-if="booking.guests.length == 2" class="text-h6 text-uppercase mb-3">Guest 1</div>

              <!-- Guest 1 Name Fields -->
              <v-row class="mx-6 mx-sm-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].name.first"
                    label="First Name"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="22"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].name.last"
                    label="Last Name"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="22"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Guest 1 Email Fields -->
              <v-row class="mx-6 mx-sm-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].contact.email"
                    label="E-mail Address"
                    :rules="[rules.email]"
                    filled
                    dense
                    maxlength="50"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="emailConfirmation"
                    label="Confirm E-mail Address"
                    :rules="emailConfirmationRules(0)"
                    filled
                    dense
                    maxlength="50"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Guest 1 Country and Phone Fields -->
              <v-row class="mx-6 mx-sm-0">
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="booking.guests[0].address.country"
                    :items="computedCountries"
                    :rules="[rules.required]"
                    item-text="country"
                    label="Country"
                    filled
                    @change="countryChanged(0)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-phone-input
                    v-model="booking.guests[0].contact.phone"
                    label="Phone Number"
                    filled
                    single-line
                    :country="phoneCountryGuest1"
                    class="d-flex flex-row"
                  />
                </v-col>
              </v-row>

              <!-- Guest 1 Address Fields -->
              <v-row v-if="booking.guests[0].address.country == 'Canada' || booking.guests[0].address.country == 'United States'" class="mx-6 mx-sm-0">
                <v-col cols="12" sm="12">
                  <!-- Canada Post Autocomplete for US & Canada -->
                  <v-autocomplete
                    v-model="selectedAddress"
                    :items="addressSuggestions"
                    item-text="formattedName"
                    label="Address Search"
                    filled
                    hide-no-data
                    return-object
                    :search-input.sync="searchQuery"
                    :loading="addressLoading"
                    cache-items
                    class="mt-4"
                    @change="onSelectAddress"
                    prepend-inner-icon="mdi-map-search-outline"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mx-6 mx-sm-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].address.street"
                    label="Street Address"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="50"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].address.city"
                    label="City"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="22"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Guest 1 Province/State and Postal Code Fields -->
              <v-row class="mx-6 mx-sm-0">
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-if="booking.guests[0].address.country == 'Canada'"
                    v-model="booking.guests[0].address.province"
                    :items="provinces"
                    :rules="[rules.required]"
                    item-value="id"
                    item-text="name"
                    label="Province"
                    filled
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else-if="booking.guests[0].address.country == 'United States'"
                    v-model="booking.guests[0].address.province"
                    :items="states"
                    :rules="[rules.required]"
                    item-value="id"
                    item-text="name"
                    label="State"
                    filled
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="booking.guests[0].address.province"
                    label="State/Province/Region"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="22"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="booking.guests[0].address.postal"
                    :label="booking.guests[0].address.country == 'United States' ? 'Zip Code' : 'Postal Code'"
                    :rules="[rules.required]"
                    filled
                    dense
                    maxlength="8"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Separator Line -->
              <div class="the-thin-grey-line flex-1 my-6"></div>

              <!-- Guest 2 -->
              <div v-if="booking.guests.length == 2">
                <div class="text-h6 text-uppercase mb-3">Guest 2</div>

                <!-- Guest 2 Name Fields -->
                <v-row class="mx-6 mx-sm-0">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="booking.guests[1].name.first"
                      label="First Name"
                      :rules="[rules.required]"
                      filled
                      dense
                      maxlength="22"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="booking.guests[1].name.last"
                      label="Last Name"
                      :rules="[rules.required]"
                      filled
                      dense
                      maxlength="22"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Guest 2 Email Fields -->
                <v-row class="mx-6 mx-sm-0">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="booking.guests[1].contact.email"
                      label="E-mail Address"
                      :rules="[rules.email]"
                      filled
                      dense
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="emailConfirmation2"
                      label="Confirm E-mail Address"
                      :rules="emailConfirmationRules(1)"
                      filled
                      dense
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Guest 2 Country and Phone Fields -->
                <v-row class="mx-6 mx-sm-0">
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="booking.guests[1].address.country"
                      :items="computedCountries"
                      :rules="[rules.required]"
                      item-text="country"
                      label="Country"
                      filled
                      @change="countryChanged(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-phone-input
                      v-model="booking.guests[1].contact.phone"
                      label="Phone Number"
                      filled
                      single-line
                      :country="phoneCountryGuest2"
                      class="d-flex flex-row"
                    />
                  </v-col>
                </v-row>

                <!-- Same Address Checkbox -->
                <v-row class="mx-6 mx-sm-0">
                  <v-col cols="12">
                    <div class="d-flex justify-center mb-3">
                      <v-checkbox v-model="useSameAddress">
                        <template v-slot:label>
                          <div class="font-weight-bold text-uppercase">Same address as guest 1</div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>

                <!-- Guest 2 Address Fields -->
                <div v-if="!useSameAddress">
                  <v-row class="mx-6 mx-sm-0">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="booking.guests[1].address.street"
                        label="Street Address"
                        :rules="[rules.required]"
                        filled
                        dense
                        maxlength="50"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="booking.guests[1].address.city"
                        label="City"
                        :rules="[rules.required]"
                        filled
                        dense
                        maxlength="22"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Guest 2 Province/State and Postal Code Fields -->
                  <v-row class="mx-6 mx-sm-0">
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-if="booking.guests[1].address.country == 'Canada'"
                        v-model="booking.guests[1].address.province"
                        :items="provinces"
                        :rules="[rules.required]"
                        item-value="id"
                        item-text="name"
                        label="Province"
                        filled
                      ></v-autocomplete>
                      <v-autocomplete
                        v-else-if="booking.guests[1].address.country == 'United States'"
                        v-model="booking.guests[1].address.province"
                        :items="states"
                        :rules="[rules.required]"
                        item-value="id"
                        item-text="name"
                        label="State"
                        filled
                      ></v-autocomplete>
                      <v-text-field
                        v-else
                        v-model="booking.guests[1].address.province"
                        label="State/Province/Region"
                        :rules="[rules.required]"
                        filled
                        dense
                        maxlength="22"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="booking.guests[1].address.postal"
                        :label="booking.guests[1].address.country == 'United States' ? 'Zip Code' : 'Postal Code'"
                        :rules="[rules.required]"
                        filled
                        dense
                        maxlength="8"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

              </div>
            </v-form>
          </template>
          <!-- ------------ Actions ------------ -->
          <div class="d-flex flex-row justify-center align-center pa-3">
            <v-btn depressed tile class="mr-2" @click="step = 1">
              PREVIOUS STEP
            </v-btn>
            <v-btn depressed tile color="#f26522" @click="validate()" :loading="isValidating" dark class="ml-2">
              NEXT STEP
            </v-btn>
          </div>
        </v-stepper-content>
        <!-- ---------------------- STEP 3: Confirmation ---------------------- -->
        <v-stepper-content step="3" v-if="tour" class="pa-0 text-center">
          <div class="g-font renedian-red--text mb-6 font-size-22">Summary & Deposit</div>
          <template>
            <!-- ------------ Trip Invoice ------------ -->
            <div class="d-flex flex-row align-center mb-6">
              <div class="the-thin-grey-line flex-1 mr-2"></div>
              <div class="renedian-burnt-orange--text font-weight-bold font-size-14 text-uppercase">Trip Invoice</div>
              <div class="the-thin-grey-line flex-1 ml-2"></div>
            </div>
            <!-- ------------ Review Message ------------ -->
            <p class="my-6 font-weight-bold font-size-14">
              Before proceeding, please review your tour summary to ensure all details are correct.<br>
            </p>
            <!-- ------ Tour Details ------ -->
            <div class="d-flex justify-center renedian-grey--text my-6">
              <div class="text-left">
                <div class="d-flex flex-row align-center my-3 text-uppercase">
                  <r-icon class="mr-2" icon="globe-grey"></r-icon>
                  <div v-if="booking.excursion" class="font-weight-bold">{{ booking.tour.name }} + {{ tour.excursion.description.name }}</div>
                  <div v-else class="font-weight-bold">{{ booking.tour.name }}</div>
                </div>
                <div class="d-flex flex-row align-center my-3 text-uppercase font-weight-bold">
                  <r-icon class="mr-2" icon="calendar-grey"></r-icon>
                  {{ formatDateFull(booking.schedule.start) }} - {{ formatDateFull(booking.schedule.finish) }}
                  <div v-if="booking.excursion" class="ml-3 font-italic font-size-11"> (Excursion starts {{ formatDateFull(tour.schedule.excursion.start) }})</div>
                </div>
              </div>
            </div>
            <!-- ------ Tour Details ------ -->
            <div v-if="booking.invoices.length" class="d-flex flex-row justify-center renedian-grey--text">
              <!-- GUEST #1 -->
              <div :class="booking.guests.length == 2 ? 'mr-8' : ''">
                <div class="font-weight-bold text-uppercase text-center mb-6">
                  {{ booking.guests[0].name.first ? booking.guests[0].name.first + " " + booking.guests[0].name.last : "Guest 1" }}
                  <div class="font-weight-bold text-uppercase font-size-12">
                    {{ booking.guests[0].address.street }}<br>
                    {{ booking.guests[0].address.city }}, {{ booking.guests[0].address.province }}<br>
                    {{ booking.guests[0].address.postal }}<br>
                    {{ booking.guests[0].address.country }}
                  </div>
                </div>
                <!-- ------------ Tour Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.tour" :key="'t1-' + index">
                  <div v-if="charge.guest == 1" class="d-flex flex-row">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Accommodation Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.accommodation" :key="'a1-' + index">
                  <div v-if="charge.guest == 1" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="booking.guests[0].preferences.roommate.type == 'known'" class="font-italic font-size-12">Sharing with {{ booking.guests[0].preferences.roommate.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Vehicle Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.vehicles" :key="'v1-' + index">
                  <div v-if="charge.guest == 1 && charge.type == 'basic'" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="charge.note" class="font-italic font-size-12">{{ charge.note }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="charge.guest == 1 && charge.type == 'option'" class="d-flex flex-row mt-2">
                    <div class="d-flex flex-column">
                      <div class="text-left ml-9">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.note }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Excursion Charges ------------ -->
                <div v-for="(charge, index) in booking.invoices[0].charges.excursion" :key="'e1-' + index">
                  <div v-if="charge.guest == 1" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="booking.guests[0].preferences.roommate.type == 'known'" class="font-italic font-size-12">Sharing with {{ booking.guests[0].preferences.roommate.name }}</div>
                        <div v-else class="font-italic font-size-12">{{ charge.note }} </div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Alumni Discount ------------ -->
                <div v-for="(charge, index) in booking.invoices[0].charges.alumni" :key="'a1-' + index">
                  <div v-if="charge.guest == 1" class="d-flex flex-row mt-4">
                    <v-icon class="mr-3 mt-1" color="#989189">mdi-earth</v-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12 green--text">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- GUEST #2 -->
              <div v-if="booking.guests.length == 2" class="ml-8">
                <div class="font-weight-bold text-uppercase text-center mb-6">
                  {{ booking.guests[1].name.first ? booking.guests[1].name.first + " " + booking.guests[1].name.last : "Guest 2" }}
                  <div class="font-weight-bold text-uppercase font-size-12">
                    {{ booking.guests[1].address.street }}<br>
                    {{ booking.guests[1].address.city }}, {{ booking.guests[1].address.province }}<br>
                    {{ booking.guests[1].address.postal }}<br>
                    {{ booking.guests[1].address.country }}
                  </div>
                </div>
                <!-- ------------ Tour Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.tour" :key="'t1-' + index">
                  <div v-if="charge.guest == 2" class="d-flex flex-row">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Accommodation Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.accommodation" :key="'a1-' + index">
                  <div v-if="charge.guest == 2" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="booking.guests[1].preferences.roommate.type == 'known'" class="font-italic font-size-12">Sharing with {{ booking.guests[1].preferences.roommate.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Vehicle Charges ------------ -->
                <div class="d-flex flex-row" v-for="(charge, index) in booking.invoices[0].charges.vehicles" :key="'v1-' + index">
                  <div v-if="charge.guest == 2 && charge.type == 'basic'" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="charge.note" class="font-italic font-size-12">{{ charge.note }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="charge.guest == 2 && charge.type == 'option'" class="d-flex flex-row mt-2">
                    <div class="d-flex flex-column">
                      <div class="text-left ml-9">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12">{{ charge.note }}</div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Excursion Charges ------------ -->
                <div v-for="(charge, index) in booking.invoices[0].charges.excursion" :key="'e1-' + index">
                  <div v-if="charge.guest == 2" class="d-flex flex-row mt-4">
                    <r-icon class="mr-3 mt-1" :icon="charge.icon + '-grey'"></r-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div v-if="booking.guests[1].preferences.roommate.type == 'known'" class="font-italic font-size-12">Sharing with {{ booking.guests[1].preferences.roommate.name }}</div>
                        <div v-else class="font-italic font-size-12">{{ charge.note }} </div>
                        <div class="font-italic font-size-12">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------------ Alumni Discount ------------ -->
                <div v-for="(charge, index) in booking.invoices[0].charges.alumni" :key="'a1-' + index">
                  <div v-if="charge.guest == 2" class="d-flex flex-row mt-4">
                    <v-icon class="mr-3 mt-1" color="#989189">mdi-earth</v-icon>
                    <div class="d-flex flex-column">
                      <div class="text-left">
                        <div class="font-weight-bold text-uppercase font-size-12">{{ charge.name }}</div>
                        <div class="font-italic font-size-12 green--text">{{ charge.amount | currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else>
              There are no invoices.
            </div> -->
            <!-- ------------ Financials ------------ -->
            <div v-if="booking.invoices.length" class="d-flex flex-column align-center mt-6">
              <div class="the-thin-grey-line-top text-uppercase font-weight-bold renedian-grey--text pt-3 px-12 font-size-12">
                Subtotal: {{ booking.invoices[0].amount.subtotal | currencyRaw }}
              </div>
              <div v-if="booking.invoices[0].amount.tax" class="mt-1 text-uppercase font-weight-bold renedian-grey--text font-size-12">
                Tax: {{ booking.invoices[0].amount.tax | currencyRaw }}
              </div>
              <div class="mt-1 text-uppercase font-weight-bold renedian-grey--text font-size-14">
                TOTAL: {{ booking.invoices[0].amount.total | currencyRaw }}
              </div>
              <div class="mt-3 text-uppercase the-thin-grey-line-box pa-4 font-weight-bold renedian-grey--text font-size-16">
                Due Today: {{ booking.invoices[0].amount.total * 0.10 | currencyRaw }}
              </div>
            </div>
            <!-- ------------ Payment Information ------------ -->
            <div class="d-flex flex-row align-center my-6">
              <div class="the-thin-grey-line flex-1 mr-2"></div>
              <div class="renedian-burnt-orange--text font-weight-bold font-size-14 text-uppercase">Payment Information</div>
              <div class="the-thin-grey-line flex-1 ml-2"></div>
            </div>
            <!-- ------ Stripe Field ------ -->
            <p v-if="booking.guests.length == 2" class="my-6 font-weight-bold font-size-14">
              Please select the guest who will be completing payment
            </p>
            <p v-else class="my-6 font-weight-bold font-size-14">
              Please enter your payment information
            </p>
            <div v-if="booking.guests.length == 2" class="d-flex flex-row my-6">
              <div class="d-flex flex-1 justify-end mr-2" @click="setBillingAddress(0)">
                <div class="billing-address-container renedian-grey--text pa-4" :class="booking.guests[0].address.billing ? 'active' : ''">
                  <div class="font-weight-bold text-uppercase text-center mb-2">
                    {{ booking.guests[0].name.first ? booking.guests[0].name.first + " " + booking.guests[0].name.last : "Guest 1" }}
                  </div>
                  <div class="font-weight-bold text-uppercase font-size-12">
                    {{ booking.guests[0].address.street }}<br>
                    {{ booking.guests[0].address.city }}, {{ booking.guests[0].address.province }}<br>
                    {{ booking.guests[0].address.postal }}<br>
                    {{ booking.guests[0].address.country }}
                  </div>
                </div>
              </div>
              <div class="d-flex flex-1 justify-start ml-2" @click="setBillingAddress(1)">
                <div class="billing-address-container renedian-grey--text pa-4" :class="booking.guests[1].address.billing ? 'active' : ''">
                  <div class="font-weight-bold text-uppercase text-center mb-2">
                    {{ booking.guests[1].name.first ? booking.guests[1].name.first + " " + booking.guests[1].name.last : "Guest 1" }}
                  </div>
                  <div class="font-weight-bold text-uppercase font-size-12">
                    {{ booking.guests[1].address.street }}<br>
                    {{ booking.guests[1].address.city }}, {{ booking.guests[1].address.province }}<br>
                    {{ booking.guests[1].address.postal }}<br>
                    {{ booking.guests[1].address.country }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="booking.guests.length == 1" class="d-flex flex-row my-6">
              <div class="d-flex flex-1 justify-center">
                <div class="billing-address-container renedian-grey--text pa-4">
                  <div class="font-weight-bold text-uppercase text-center mb-2">
                    {{ booking.guests[0].name.first ? booking.guests[0].name.first + " " + booking.guests[0].name.last : "Guest 1" }}
                  </div>
                  <div class="font-weight-bold text-uppercase font-size-12">
                    {{ booking.guests[0].address.street }}<br>
                    {{ booking.guests[0].address.city }}, {{ booking.guests[0].address.province }}<br>
                    {{ booking.guests[0].address.postal }}, {{ booking.guests[0].address.country }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ------------ Stripe Card ------------ -->
            <div class="d-flex flex-column align-center">
              <div style="width: 500px;">
                <v-stripe-card 
                  v-model="payment.token" 
                  :api-key="stripeKey"
                  filled
                  label="Payment Information"
                  hint="Please enter card details"
                  :hidePostalCode="true"
                  :loading="processingPayment"
                  :options="payment.options"
                  :disabled="paymentCardStatus > 0"
                  @input="tokenInput()"
                ></v-stripe-card>
              </div>
              <div class="d-flex flex-row justify-center">
                <v-btn tile v-if="paymentCardStatus == 0" dark color="primary" @click="verifyCard()">Verify Card</v-btn>
                <v-btn tile v-if="paymentCardStatus == 1" :loading="true" @click="verifyCard()">Verifying Card ...</v-btn>
                <v-btn tile v-if="paymentCardStatus == 2" dark color="green"><v-icon left>mdi-check</v-icon>Card Verified</v-btn>
                <v-btn tile v-if="paymentCardStatus == 2" @click="resetCard()" class="ml-2">Edit Card</v-btn>
              </div>
            </div>
            <div v-if="paymentError" class="d-flex flex-row justify-center mt-3">
              <v-alert type="error">
                <!-- There was an issue processing your payment. Please check your details and try again. -->
                {{ paymentError }}
              </v-alert>
            </div>
            <div class="mb-6">
              <img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fr-stripe.png?alt=media&token=01b7b205-114d-4c82-8eb3-ccc5235a1b68" style="width: 400px;">
            </div>

            <!-- ------------ Cancellation Policy ------------ -->
            <div class="d-flex flex-row align-center">
              <div class="the-thin-grey-line flex-1 mr-2"></div>
              <div class="renedian-burnt-orange--text font-weight-bold font-size-14">CANCELLATION POLICY AND BOOKING TERMS & CONDITIONS</div>
              <div class="the-thin-grey-line flex-1 ml-2"></div>
            </div>
            <v-card v-if="!cancellationTermsAccepted" elevation="2" class="ma-4">
              <v-card-text class="px-12 py-6">
                <div class="renedian-red--text font-weight-bold text-uppercase">Deposit</div>
                <p class="black--text mb-6 font-size-12">
                  A 10% deposit is required at the time of booking to secure your place on tour. Final payment is due in full 60 days before the start of your tour.
                  If your booking is made within 60 days of the tour starting, full payment is required at the time of booking.
                </p>
                <div class="renedian-red--text font-weight-bold text-uppercase">Cancellation up to 60 days before</div>
                <p class="black--text mb-6 font-size-12">
                  If this booking is canceled by you for any reason up until 60 days before the tour starts, your full deposit minus a CAN$250 admin fee will be returned to you. 
                  You may select to transfer the full deposit to another Renedian tour, in which event no admin fee will be levied.
                </p>
                <div class="renedian-red--text font-weight-bold text-uppercase">Cancellation 30-60 days before, after final invoice is paid</div>
                <p class="black--text mb-6 font-size-12">
                  If this booking is canceled by you after the final invoice is paid and your cancelation is made between 60 and 30 days before your tour starts, 50% of the total invoiced
                  amount will be retained by Renedian for expenses incurred on your behalf.
                </p>
                <div class="renedian-red--text font-weight-bold text-uppercase">Cancellation 30 days before, after final invoice is paid</div>
                <p class="black--text mb-6 font-size-12">
                  If this booking is canceled by you in writing after the final invoice is paid and your cancelation is made less than 30 days prior to the start of your tour,
                  100% of the invoiced amount will be retained by Renedian for expenses incurred on your behalf.
                </p>
                <div class="renedian-red--text font-weight-bold text-uppercase">Cancellation by Renedian</div>
                <p class="black--text mb-6 font-size-12">
                  If your tour is canceled by Renedian, your full payment is refundable or transferrable to another tour, and no admin fee will be levied.<br>
                  <br>
                  Our tours require a minimum number of participants to run, and in light of that we strongly advise you not to buy any non-refundable items (such as flights,
                  insurance, etc.) until we have confirmed that your tour will depart. We normally are able to confirm either way at least 90
                  days prior to the start date.
                </p>
                <div>
                  <a href="https://renedian.com/terms-and-conditions" class="renedian-orange--text text-uppercase" target="_blank">Read full version of cancellation policy and booking terms & conditions</a>
                </div>
              </v-card-text>
            </v-card>
            <div class="d-flex justify-center mx-6">
              <v-checkbox v-model="cancellationTermsAccepted">
                <template v-slot:label>
                  <div class="font-weight-bold text-uppercase">I have read and agree to the cancellation policy and booking terms & conditions</div>
                </template>
              </v-checkbox>
            </div>
          </template>
          <!-- ------------ Actions ------------ -->
          <div class="d-flex flex-row justify-center align-center mt-12 pa-3">
            <v-btn depressed tile class="mr-2" @click="step = 2" :disabled="processingPayment">
              PREVIOUS STEP
            </v-btn>
            <v-btn depressed tile color="#f26522" :dark="cancellationTermsAccepted && payment.token ? true : false" @click="bookTrip()" :loading="processingPayment" :disabled="!cancellationTermsAccepted || !payment.token" :class="cancellationTermsAccepted && payment.token ? 'glow-button' : ''" class="ml-2">
              BOOK TOUR
            </v-btn>
          </div>
        </v-stepper-content>
        <!-- ====================== Payment Successful ====================== -->
        <v-stepper-content step="4" class="pa-0 text-center">
          <div class="checkmark-container d-flex flex-row justify-center mt-4">
            <div class="checkmark">
              <svg id="checkmark-svg" class="run-animation" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.3 193.3">
                <circle class="cls-1 circle" cx="96.65" cy="96.65" r="94.15"/>
                <polyline class="cls-1 checkmark" points="46.9 101.4 76.9 131.4 146.4 61.9"/>
              </svg>
              <!-- <svg id="cross-svg" class="run-animation" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.3 193.3">
                <circle class="cls-2 circle" cx="96.65" cy="96.65" r="94.15"/>
                <polyline class="cls-2 checkmark" points="54.15 136.65, 136.65 54.15"/>
                <polyline class="cls-2 checkmark" points="136.65 136.65, 54.15 54.15"/>
              </svg> -->
            </div>
          </div>
          <div class="my-6">
            <div class="success-title font-weight-bold mb-2">Your Booking is Confirmed</div>
            <div class="font-size-20">We have received your deposit and you will receive the following emails shortly with your:</div>
          </div>
          <div class="d-flex justify-center mb-12">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row align-center">
                <v-icon color="green" class="mr-2">mdi-check</v-icon>
                <!-- <v-icon v-if="emails.confirmation" color="green" class="mr-2">{{ emails.confirmation ? 'mdi-check' : 'mdi-dots-horizontal' }}</v-icon> -->
                <!-- <v-progress-circular v-else size="20" class="mr-2" indeterminate color="grey"></v-progress-circular> -->
                Booking Confirmation & Invoice
              </div>
              <div class="d-flex flex-row align-center">
                <v-icon color="green" class="mr-2">mdi-check</v-icon>
                <!-- <v-icon v-if="emails.receipt" color="green" class="mr-2">{{ emails.receipt ? 'mdi-check' : 'mdi-dots-horizontal' }}</v-icon> -->
                <!-- <v-progress-circular v-else size="20" class="mr-2" indeterminate color="grey"></v-progress-circular> -->
                Deposit Receipt
              </div>
              <div class="d-flex flex-row align-center">
                <v-icon color="green" class="mr-2">mdi-check</v-icon>
                <!-- <v-icon v-if="emails.portal" color="green" class="mr-2">{{ emails.portal ? 'mdi-check' : 'mdi-dots-horizontal' }}</v-icon> -->
                <!-- <v-progress-circular v-else size="20" class="mr-2" indeterminate color="grey"></v-progress-circular> -->
                Rider Portal link
              </div>
            </div>
          </div>
          <div class="mb-12">
            <div class="font-italic">
              If these emails do not arrive shortly or you need further assistance of any kind, please <a @click="inquiryDialog = true">contact us</a>.
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- ---------------------- Invalid Tour ---------------------- -->
    <v-alert v-if="invalidTour" type="error" class="mt-6">
      {{ invalidTour }}
    </v-alert>
    <!-- ---------------------- Excursion Dialog ---------------------- -->
    <v-dialog v-if="tour && tour?.excursion?.description?.name" v-model="excursionDialog" width="75%">
      <v-card>
        <div class="d-flex flex-row">``
          <div class="excursion-dialog-photo"></div>
          <div class="pa-12">
            <h2 class="mb-6">{{ tour?.excursion?.description?.name }}</h2>
            {{ tour?.excursion?.description?.long }}
            <div class="d-flex flex-row justify-end mt-6">
              <v-btn color="orange" text @click="excursionDialog = false">Close</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- ---------------------- Enquiry Dialog ---------------------- -->
    <v-dialog v-model="inquiryDialog" width="600">
      <v-form ref="enquiry">
        <v-card>
          <v-card-title>
            {{ tour && tour.details && tour.details.name ? "Enquire about " + tour.details.name :  "Send an Enquiry" }}
            <v-spacer></v-spacer>
            <v-btn icon @click="inquiryDialog = false">
              <v-icon color="orange">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="enquiry.status != 'sent'" class="mt-6">
            <v-alert v-if="enquiry.status == 'error'" type="error">
              Please provide all fields so we may better assist you.
            </v-alert>
            <v-text-field v-model="enquiry.name" label="Name" :rules="[rules.required]" filled dense maxlength="22"></v-text-field>
            <div class="font-size-14 font-weight-bold">Contact Preference</div>
            <div v-if="enquiry.preference == 'email'" class="d-flex flex-row mb-6">
              <div class="d-flex flex-1 justify-center align-center py-1">
                <v-btn icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                <div class="font-weight-bold text-uppercase" style="color: #0009">By Email</div>
              </div>
              <div @click="enquiry.preference = 'phone'" class="d-flex flex-1 justify-center align-center py-1">
                <v-btn icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                <div class="font-weight-bold text-uppercase" style="color: #0009">By Phone</div>
              </div>
            </div>
            <div v-else class="d-flex flex-row mb-6">
              <div @click="enquiry.preference = 'email'" class="d-flex flex-1 justify-center align-center py-1">
                <v-btn icon><v-icon>mdi-radiobox-blank</v-icon></v-btn>
                <div class="font-weight-bold text-uppercase" style="color: #0009">By Email</div>
              </div>
              <div class="d-flex flex-1 justify-center align-center py-1">
                <v-btn icon><v-icon color="#ef6c00">mdi-radiobox-marked</v-icon></v-btn>
                <div class="font-weight-bold text-uppercase" style="color: #0009">By Phone</div>
              </div>
            </div>
            <v-text-field v-if="enquiry.preference == 'email'" v-model="enquiry.email" label="Email" filled dense maxlength="50"></v-text-field>
            <v-text-field v-if="enquiry.preference == 'phone'" v-model="enquiry.phone" label="Phone" filled dense maxlength="18"></v-text-field>
            <v-textarea v-model="enquiry.message" label="Message" filled dense maxlength="500"></v-textarea>  
            <div class="d-flex flex-row justify-end">
              <v-btn color="primary" tile dark @click="sendEnquiry()" :loading="enquiry.status == 'sending' || enquiry.status == 'saved'">Send Enquiry</v-btn>
            </div>
          </v-card-text>
          <v-card-text v-else class="mt-6">
            <div class="d-flex justify-center align-center">
              <v-icon color="green" style="font-size: 120px">mdi-check-circle-outline</v-icon>
            </div>
            <div class="text-center mt-6">
              <div class="font-weight-bold font-size-20 mb-6">Thank you for your interest.</div>
              <div class="font-size-14 mb-12">Your message has been received. We will be in touch with you shortly.</div>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- ---------------------- Explainer Dialog ---------------------- -->
    <v-dialog v-model="explainerDialog" width="600">
      <v-card @click="explainerDialog = false">
        <v-btn @click="explainerDialog = false" icon class="absolute-close"><v-icon>mdi-close</v-icon></v-btn>
        <v-img src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbooking-popup.svg?alt=media&token=e95c8350-8253-408b-8d7b-13879d60625c"></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getFirestore, doc, getDoc, getDocs, addDoc, setDoc, deleteDoc, collection } from 'firebase/firestore'
import 'v-stripe-elements/dist/v-stripe-elements.min.css';
import { DateTime } from "luxon"
import axios from 'axios'
import RenedianIcon from '/src/components/RenedianIcon.vue'
import Invoice from '/src/components/Invoice.vue'
import { isEqual, cloneDeep } from 'lodash-es';
export default {
  name: 'Booking',
  components: {
    'r-icon': RenedianIcon, // Make sure to use the correct tag,
    Invoice
  },
  data: () => ({
    // ------------ Common ------------
    step: 1,
    id: '',
    availableTours: [],
    selectedTour: null,
    selectedTourId: null,
    emailConfirmation: '',
    emailConfirmation2: '',
    paymentError: '',
    stripeLoaded: false,
    processingPayment: false,
    cancellationTermsAccepted: false,
    useSameAddress: true,
    explainerDialog: true,
    excursionDialog: false,
    inquiryDialog: false,
    loading: true,
    isValidating: false,
    pricingDetails: false,
    selectedVehicles: [[],[]],
    paymentCardStatus: 0,
    countries: require('@/assets/continents.json'),
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return pattern.test(value) || 'Invalid email address.';
      },
      match: (value, other) => value === other || 'E-mails do not match.',
    },
    emails: {
      confirmation: false, // Email #1.1: Confirmation + Invoice (Guest 1)
      // confirmation2: false, // Email #1.2: Confirmation + Invoice (Guest 2)
      receipt: false, // Email #2: Receipt
      portal: false, // Email #3.1: Portal Link
      // portal2: false, // Email #3.2: Portal Link
      note: false, // Email #4.1: Note from Rene
      // note2: false, // Email #4.2: Note from Rene
      internal: false, // Email #5: Internal Accounting
    },
    interestId: '', // Used to track interest in a tour
    // ------------ Models ------------
    payment: {
      token: '',
      options: {
        name: '',
        address_zip: '',
        address_country: '',
      }
    },
    bookingDefault: {
      id: '',
      user: {
        id: '',
        email: '',
        new: null,
      },
      confirmed: false,
      tour: {
        id: '',
        name: '',
      },
      schedule: {
      },
      itinerary: [],
      excursion: false,
      charges: [],
      invoices: [],
      payments: [],
      guests: [],
      notes: []
    },
    booking: null,
    guestDefault: {
      id: '',
      name: {
        first: '',
        last: ''
      },
      contact: {
        email: '',
        phone: '',
      },
      address: {
        street: '',
        city: '',
        province: '',
        postal: '',
        country: 'Canada',
        billing: true,
      },
      vehicle: {
        make: '',
        model: '',
        options: [],
      },
      preferences: {
        riding: '',
        room: '',
        dietary: '',
        shirt: '',
        roommate: {
          type: '',
          name: '',
        }
      },
      returning: 0,
      actions: {
        passport: {
          complete: false,
          fullName: '',
          number: '',
          dob: '',
          gender: '',
          expiryDate: '',
          issuingAuthority: '',
          photo: '',
          photoURL: '',
        },
        driversLicense: {
          complete: false,
          fullName: '',
          number: '',
          expiryDate: '',
          frontPhotoURL: '',
          frontPhoto: '',
          backPhotoURL: '',
          backPhoto: '',
        },
        preferences: {
          complete: false,
          tshirtSize: '',
          dietaryRequirement: '',
          dietaryOther: '',
        },
        medical: {
          complete: false,
          hasAllergies: null,
          allergies: '',
          hasMedicalNeeds: null,
          medicalNeeds: '',
        },
        emergencyContact: {
          complete: false,
          name: '',
          phone: '',
          email: '',
        },
        waiver: {
          complete: false,
          acknowledgement: {
            safety: false,
            risks: false,
            indemnity: false,
            release: false,
          },
          signature: {
            name: '',
            date: '',
            image: null,
          }
        },
      },
      checklist: [],
      documents: [],
      notes: ''
    },
    enquiry: {
      name: '',
      preference: 'email',
      email: '',
      phone: '',
      message: '',
      status: '',
      date: DateTime.now().toFormat('MMMM d, yyyy')
    },
    sizes: [
      { id: 'x-small', name: 'Unisex Extra Small' },
      { id: 'small', name: 'Unisex Small' },
      { id: 'medium', name: 'Unisex Medium' },
      { id: 'large', name: 'Unisex Large' },
      { id: 'x-large', name: 'Unisex X-Large' },
      { id: 'xx-large', name: 'Unisex 2X-Large' },
      { id: 'xxx-large', name: 'Unisex 3X-Large' },
    ],
    dietary: [
      { id: 'none', name: 'No' },
      { id: 'vegetarian', name: 'Vegetarian' },
      { id: 'vegan', name: 'Vegan' },
      { id: 'gluten-free', name: 'Gluten-Free' },
      { id: 'dairy-free', name: 'Dairy-Free' },
      { id: 'other', name: 'Other (will notify)' },
    ],
    provinces: [
      {"id": "AB", "name": "Alberta"},
      {"id": "BC", "name": "British Columbia"},
      {"id": "MB", "name": "Manitoba"},
      {"id": "NB", "name": "New Brunswick"},
      {"id": "NL", "name": "Newfoundland and Labrador"},
      {"id": "NS", "name": "Nova Scotia"},
      {"id": "ON", "name": "Ontario"},
      {"id": "PE", "name": "Prince Edward Island"},
      {"id": "QC", "name": "Quebec"},
      {"id": "SK", "name": "Saskatchewan"},
      {"id": "NT", "name": "Northwest Territories"},
      {"id": "NU", "name": "Nunavut"},
      {"id": "YT", "name": "Yukon"}
    ],
    states: [
      {"id": "AL", "name": "Alabama"},
      {"id": "AK", "name": "Alaska"},
      {"id": "AZ", "name": "Arizona"},
      {"id": "AR", "name": "Arkansas"},
      {"id": "CA", "name": "California"},
      {"id": "CO", "name": "Colorado"},
      {"id": "CT", "name": "Connecticut"},
      {"id": "DE", "name": "Delaware"},
      {"id": "FL", "name": "Florida"},
      {"id": "GA", "name": "Georgia"},
      {"id": "HI", "name": "Hawaii"},
      {"id": "ID", "name": "Idaho"},
      {"id": "IL", "name": "Illinois"},
      {"id": "IN", "name": "Indiana"},
      {"id": "IA", "name": "Iowa"},
      {"id": "KS", "name": "Kansas"},
      {"id": "KY", "name": "Kentucky"},
      {"id": "LA", "name": "Louisiana"},
      {"id": "ME", "name": "Maine"},
      {"id": "MD", "name": "Maryland"},
      {"id": "MA", "name": "Massachusetts"},
      {"id": "MI", "name": "Michigan"},
      {"id": "MN", "name": "Minnesota"},
      {"id": "MS", "name": "Mississippi"},
      {"id": "MO", "name": "Missouri"},
      {"id": "MT", "name": "Montana"},
      {"id": "NE", "name": "Nebraska"},
      {"id": "NV", "name": "Nevada"},
      {"id": "NH", "name": "New Hampshire"},
      {"id": "NJ", "name": "New Jersey"},
      {"id": "NM", "name": "New Mexico"},
      {"id": "NY", "name": "New York"},
      {"id": "NC", "name": "North Carolina"},
      {"id": "ND", "name": "North Dakota"},
      {"id": "OH", "name": "Ohio"},
      {"id": "OK", "name": "Oklahoma"},
      {"id": "OR", "name": "Oregon"},
      {"id": "PA", "name": "Pennsylvania"},
      {"id": "RI", "name": "Rhode Island"},
      {"id": "SC", "name": "South Carolina"},
      {"id": "SD", "name": "South Dakota"},
      {"id": "TN", "name": "Tennessee"},
      {"id": "TX", "name": "Texas"},
      {"id": "UT", "name": "Utah"},
      {"id": "VT", "name": "Vermont"},
      {"id": "VA", "name": "Virginia"},
      {"id": "WA", "name": "Washington"},
      {"id": "WV", "name": "West Virginia"},
      {"id": "WI", "name": "Wisconsin"},
      {"id": "WY", "name": "Wyoming"}
    ],
    // ------------ Data ------------
    tour: null,
    tours: [],
    templates: [],
    // ------------ Errors ------------
    invalidTour: '',
    // ------------ Canada Post ------------
    searchQuery: '',
    addressSuggestions: [],
    addressLoading: false,
    selectedAddress: null,
    // CanadaPostGuest1Address: '',
    // CanadaPostGuest2Address: '',
    // canadaPostLoaded: false,
    // addressSuggestions: [],
    // addressLoading: false,
    canadaPostApiKey: 'MF59-NH89-GA34-AF11',
  }),
  mounted() {
    // ---------------------- Load Stripe ----------------------
    // Check if Stripe.js is already loaded
    if (window.Stripe) {
      this.stripeLoaded = true;
      // this.initializeStripe(); // Initialize Stripe functionality
    } else {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        this.stripeLoaded = true;
        console.log('Stripe.js has loaded.')
      };
      script.onerror = (error) => {
        console.error('Failed to load Stripe.js', error);
      };
      document.head.appendChild(script);
    }
    // ---------------------- Initialize ----------------------
    this.init()
    // ---------------------- Load Canada Post ----------------------
    this.loadCanadaPost()
  },
  methods: {
    init() {
      this.id = this.$route.params.id
      this.booking = cloneDeep(this.bookingDefault)
      this.tour = null
      if (this.id) {
        this.load('tours', this.id)
      } else {
        this.load('tours')
        this.load('templates')
      }
    },
    load(table, id) {
      const db = getFirestore()
      const collectionRef = collection(db, table)
      if (id) {
        const docRef = doc(collectionRef, id)
        getDoc(docRef).then((doc) => {
          if (doc.exists()) {
            let item = doc.data()
            item.id = doc.id
            table = table.slice(0, -1) // make singular: remove 's' from end of table name
            this.$set(this, table, item)
            this.loaded(table)
            this.loading = false
          } else {
            console.log('No such document!')
          }
        }).catch((error) => {
          console.log('Error getting document:', error)
        })
      } else {
        getDocs(collectionRef).then((querySnapshot) => {
          let items = []
          querySnapshot.forEach((doc) => {
            let item = doc.data()
            item.id = doc.id
            items.push(item)
          })
          this.$set(this, table, items)
          this.loaded(table)
          this.loading = false
        })
      }
    },
    loaded(table) {
      console.log(table, 'loaded')
      if (table === 'tour') {
        // A tour has been loaded. We must check to make sure it is still valid.
        // 1. Check if the tour is open for booking (or private)
        if (this.tour.status === 'open' || this.tour.status === 'private') {
          // 2. Check if it is still in the future
          const tourStartDate = new Date(this.tour.schedule.primary.start).getTime();
          const currentDate = Date.now();
          if (tourStartDate < currentDate) {
            // The tour has already started
            this.invalidTour = 'This tour has already started'
            console.log('Tour is not open for booking 2')
            return
          }
        } else {
          // The tour is not open for booking
          this.invalidTour = 'This tour is not open for booking'
          console.log('Tour is not open for booking')
          return
        }

        this.booking.tour = {
          id: this.tour.id,
          name: this.tour.template.details.name,
        }
        this.booking.schedule = this.tour.schedule.primary
        this.booking.itinerary = this.tour.itinerary
        this.booking.guests = [cloneDeep(this.guestDefault)] // Deep copy
        // ------------ Set defaults ------------
        this.booking.guests[0].preferences.riding = 'bike'
        this.booking.guests[0].preferences.room = 'shared'
        this.booking.guests[0].preferences.roommate.type = 'unknown'
        // Find the vehicle with $0 value
        this.booking.guests[0].vehicle = this.computedVehicles.find( vehicle => vehicle.price == 0 )
        this.selectedVehicles[0] = cloneDeep(this.booking.guests[0].vehicle)
        this.selectedVehicles[1] = cloneDeep(this.selectedVehicles[0])
        // Just default to the first one
        if (!this.booking.guests[0].vehicle) this.booking.guests[0].vehicle = this.computedVehicles[0]
        // ------------ Default Charges ------------
        // Calculate default charges
        this.calculateCharges()
      }
      if (table === 'tours') {
        // The tours table has been loaded. We now create a list of available tours.
        // This means tours which are open for booking. They must be nested under their tour name.
        this.availableTours = []

        this.tours.forEach(tour => {
          const tourStartDate = new Date(tour.schedule.primary.start).getTime();
          const currentDate = Date.now();

          if ((tour.status === 'open' || tour.status === 'cancelled') && tourStartDate > currentDate) {
            // if a tour with the same name already exists, add this date to the list of dates
            // otherwise, add a new tour to the list of available tours
            let existingTour = this.availableTours.find(availableTour => availableTour.name === tour.template.details.name);
            if (existingTour && tour.status === 'open') {
              existingTour.dates.push({
                id: tour.id,
                schedule: this.formatYear(tour.schedule.primary.start) + ': ' + this.formatDate(tour.schedule.primary.start) + ' - ' + this.formatDate(tour.schedule.primary.finish),
                unix: this.unixDate(tour.schedule.primary.start),
                disabled: false
              });
            } else {
              this.availableTours.push({
                name: tour.template.details.name,
                dates: [{
                  id: tour.id,
                  schedule: this.formatYear(tour.schedule.primary.start) + ': ' + this.formatDate(tour.schedule.primary.start) + ' - ' + this.formatDate(tour.schedule.primary.finish) + (tour.status === 'cancelled' ? ' (FULL)' : ''),
                  unix: this.unixDate(tour.schedule.primary.start),
                  disabled: tour.status === 'cancelled'
                }],
                status: tour.status
              });
            }
          }
        });
      }
    },
    ridingPreferenceChanged() {
      // If the riding preference was changed from a bike to anything else, 
      // we need to remove the vehicle from the booking
      if (this.booking.guests[0].preferences.riding != 'bike') {
        this.booking.guests[0].vehicle = {}
      }
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding != 'bike') {
        this.booking.guests[1].vehicle = {}
      }
      // If the riding preference was changed from anything else to a bike,
      // we need to add a vehicle to the booking
      if (this.booking.guests[0].preferences.riding == 'bike' && !this.booking.guests[0].vehicle.id) {
        // deep copy default
        this.booking.guests[0].vehicle = cloneDeep(this.selectedVehicles[0])
      }
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'bike' && !this.booking.guests[1].vehicle.id) {
        // deep copy default
        this.booking.guests[1].vehicle = cloneDeep(this.selectedVehicles[1])
      }
      // Edge case: Guest 1 selected rider, guest 2 selected passenger. Guest 1 then changes to van rider. We need to change guest 2 to van passenger.
      if (this.booking.guests.length == 2 && this.booking.guests[0].preferences.riding == 'van' && this.booking.guests[1].preferences.riding == 'passenger') {
        this.booking.guests[1].preferences.riding = 'van'
      }
      // Recalculate the charges
      this.calculateCharges()
    },
    accomodationPreferenceChanged() {
      // If there are two guests we need to make sure they are in sync
      if (this.booking.guests.length == 2) {
        if (this.booking.guests[0].preferences.room == 'shared') {
          this.booking.guests[0].preferences.roommate.type = 'known'
          this.booking.guests[0].preferences.roommate.name = 'guest #2'
          this.booking.guests[1].preferences.room = 'shared'
          this.booking.guests[1].preferences.roommate.type = 'known'
          this.booking.guests[1].preferences.roommate.name = 'guest #1'
        } else if (this.booking.guests[0].preferences.room == 'solo') {
          this.booking.guests[0].preferences.roommate.type = 'none'
          this.booking.guests[0].preferences.roommate.name = ''
          this.booking.guests[1].preferences.room = 'solo'
          this.booking.guests[1].preferences.roommate.type = 'none'
          this.booking.guests[1].preferences.roommate.name = ''
        }
      } else {
        // Check for the case of a solo guest changing to a solo room
        if (this.booking.guests[0].preferences.room == 'solo') {
          this.booking.guests[0].preferences.roommate.type = 'none'
          this.booking.guests[0].preferences.roommate.name = ''
        } else if (this.booking.guests[0].preferences.room == 'shared') {
          if (this.booking.guests[0].preferences.roommate.type == 'unknown') {
            this.booking.guests[0].preferences.roommate.name = ''
          }
        }
      }
      // Recalculate the charges
      this.calculateCharges()
    },
    vehiclePreferenceChanged(guestIndex) {
      // We deep copy the guest vehicle to the selected vehicle
      this.booking.guests[guestIndex].vehicle = cloneDeep(this.selectedVehicles[guestIndex])
      // We iterate through the options and deselect them
      this.booking.guests[guestIndex].vehicle.options.forEach( option => {
        option.selected = false
      })
      // Recalculate the charges
      this.calculateCharges()
    },
    unixDate(value) {
      return DateTime.fromISO(value).toMillis()
    },
    formatYear(value) {
      return DateTime.fromISO(value).toFormat('yyyy')
    },
    formatDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d')
    },
    formatDateSimple(value) { // day/month/year
      return DateTime.fromISO(value).toFormat('d/M/yyyy')
    },
    formatDateFull(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    },
    calculateCharges() {
      // ---------------------- Initialize Guests ----------------------
      // Calculate the charges for the booking
      this.booking.charges = []
      this.booking.summary = [{},{}]
      // ---------------------- 1. Base Prices ----------------------
      this.booking.charges.push({
        type: 'tour',
        name: this.tour.template.details.name,
        items: []
      })
      // Guest #1
      if (this.booking.guests[0].preferences.riding == 'bike') {
        this.booking.charges[0].items.push({
          name: 'Motorcycle Rider',
          type: 'basic',
          quantity: 1,
          amount: this.tour.details.pricing.base,
        })
      } else if (this.booking.guests[0].preferences.riding == 'van') {
        this.booking.charges[0].items.push({
          name: 'Van Passenger',
          type: 'basic',
          quantity: 1,
          amount: this.tour.details.pricing.passenger,
        })
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'bike') {
        this.booking.charges[0].items.push({
          name: 'Motorcycle Rider',
          type: 'basic',
          quantity: 1,
          amount: this.tour.details.pricing.base,
        })
      } else if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'van') {
        this.booking.charges[0].items.push({
          name: 'Van Passenger',
          type: 'basic',
          quantity: 1,
          amount: this.tour.details.pricing.passenger,
        })
      } else if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'passenger') {
        this.booking.charges[0].items.push({
          name: 'Motorcycle Passenger',
          type: 'basic',
          quantity: 1,
          amount: this.tour.details.pricing.passenger,
        })
      }
      
      // ---------------------- 2. Room Supplement ----------------------
      this.booking.charges.push({
        type: 'room',
        name: 'Accommodation',
        items: []
      })
      // Guest #1
      if (this.booking.guests.length == 1 && this.booking.guests[0].preferences.room == 'solo') {
        this.booking.charges[1].items.push({
          name: 'Solo Room Upgrade',
          type: 'upgrade',
          quantity: 1,
          amount: this.tour.details.pricing.solo,
        })
      } else if (this.booking.guests.length == 1) {
        // Shared Room
        this.booking.charges[1].items.push({
          name: 'Shared Room',
          type: 'basic',
          quantity: 1,
          amount: 0,
        })
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.room == 'solo') {
        this.booking.charges[1].items.push({
          name: 'Separate Rooms Upgrade',
          type: 'upgrade',
          quantity: 2,
          amount: this.tour.details.pricing.solo,
        })
      } else if (this.booking.guests.length == 2) {
        // Shared Room
        this.booking.charges[1].items.push({
          name: 'Shared Room',
          type: 'basic',
          quantity: 2,
          amount: 0,
        })
      }
      
      // ---------------------- 3. Vehicles ----------------------
      this.booking.charges.push({
        type: 'vehicle',
        name: 'Vehicles',
        items: []
      })
      // Guest #1
      if (this.booking.guests[0].preferences.riding == 'bike') {
        // Vehicle
        this.booking.charges[2].items.push({
          name: this.booking.guests[0].vehicle.make + ' ' + this.booking.guests[0].vehicle.model,
          type: 'basic',
          quantity: 1,
          amount: this.booking.guests[0].vehicle.price,
        })
        // Options
        this.booking.guests[0].vehicle.options.forEach( option => {
          if (option.selected) {
            this.booking.charges[2].items.push({
              name: option.name,
              type: 'option',
              quantity: 1,
              amount: option.price,
            })
          }
        })
      } else {
        // No motorcycle
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'bike') {
        // Vehicle
        this.booking.charges[2].items.push({
          name: this.booking.guests[1].vehicle.make + ' ' + this.booking.guests[1].vehicle.model,
          type: 'basic',
          quantity: 1,
          amount: this.booking.guests[1].vehicle.price,
        })
        // Options
        this.booking.guests[1].vehicle.options.forEach( option => {
          if (option.selected) {
            this.booking.charges[2].items.push({
              name: '+' + option.name,
              type: 'option',
              quantity: 1,
              amount: option.price,
            })
          }
        })
      } else {
        // No motorcycle
      }

      // ---------------------- 4. Excursion ----------------------
      this.booking.charges.push({
        type: 'excursion',
        name: 'Excursion',
        items: []
      })
      // Optional excursion
      if (this.booking.excursion) {
        this.booking.charges[3].items.push({
          name: this.tour.excursion.description.name,
          type: 'base',
          quantity: this.booking.guests.length,
          amount: this.booking.guests.length * Number(this.tour.excursion.pricing.base)
        })
        // Solo room upgrade?
        if (this.booking.guests.length == 1 && this.booking.guests[0].preferences.room == 'solo') {
          this.booking.charges[3].items.push({
            name: 'Solo Room Upgrade',
            type: 'option',
            quantity: 1,
            amount: Number(this.tour.excursion.pricing.solo)
          })
        }
        if (this.booking.guests.length == 2 && this.booking.guests[0].preferences.room == 'solo') {
          this.booking.charges[3].items.push({
            name: 'Separate Rooms Upgrade',
            type: 'option',
            quantity: 2,
            amount: Number(this.tour.excursion.pricing.solo)
          })
        }
      }

      // ---------------------- 5. Alumni Credits ----------------------
      // Add credit for guest 1
      if (this.booking.guests[0].returning > 1) {
        this.booking.charges.push({
          name: 'Alumni Discount (Guest 1)',
          type: 'credit',
          items: [{
            name: this.booking.guests[0].returning + (this.booking.guests[0].returning > 4 ? '+ tours' : ' tours'),
            amount: -400 * (this.booking.guests[0].returning - 1),
            quantity: 1
          }]
        })
      }
      // Add credit for guest 2
      if (this.booking.guests.length == 2 && this.booking.guests[1].returning > 1) {
        this.booking.charges.push({
          name: 'Alumni Discount (Guest 2)',
          type: 'credit',
          items: [{
            name: this.booking.guests[1].returning + (this.booking.guests[1].returning > 4 ? '+ tours' : ' tours'),
            amount: -400 * (this.booking.guests[1].returning - 1),
            quantity: 1
          }]
        })
      }
    },
    calculateInvoice() {
      // ---------------------- Initialize Invoice ----------------------
      let invoice = {
        date: DateTime.now().toISO(),
        number: '',
        tour: this.tour.template.details.name,
        charges: {
          tour: [],
          accommodation: [],
          vehicles: [],
          excursion: [],
          uncategorized: [],
          alumni: []
        },
        payments: [],
        amount: {
          subtotal: 0,
          tax: 0,
          total: 0,
        },
        balance: 0,
        status: 'unpaid',
        notes: ''
      }
      // ---------------------- 1. Base Prices ----------------------
      // Guest #1
      if (this.booking.guests[0].preferences.riding == 'bike') {
        invoice.charges.tour.push({
          name: 'Motorcycle Rider',
          type: 'basic',
          icon: 'solo',
          guest: 1,
          note: '',
          amount: this.tour.details.pricing.base,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.base)
      } else if (this.booking.guests[0].preferences.riding == 'van') {
        invoice.charges.tour.push({
          name: 'Van Passenger',
          type: 'basic',
          icon: 'van',
          guest: 1,
          note: '',
          amount: this.tour.details.pricing.passenger,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.passenger)
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'bike') {
        invoice.charges.tour.push({
          name: 'Motorcycle Rider',
          type: 'basic',
          icon: 'solo',
          guest: 2,
          note: '',
          amount: this.tour.details.pricing.base,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.base)
      } else if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'van') {
        invoice.charges.tour.push({
          name: 'Van Passenger',
          type: 'basic',
          icon: 'van',
          guest: 2,
          note: '',
          amount: this.tour.details.pricing.passenger,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.passenger)
      } else if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'passenger') {
        invoice.charges.tour.push({
          name: 'Motorcycle Passenger',
          type: 'basic',
          icon: 'passenger',
          guest: 2,
          note: '',
          amount: this.tour.details.pricing.passenger,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.passenger)
      }
      
      // ---------------------- 2. Room Supplement ----------------------
      // Guest #1
      if (this.booking.guests.length == 1 && this.booking.guests[0].preferences.room == 'solo') {
        // Solo Room
        invoice.charges.accommodation.push({
          name: 'Solo Room',
          icon: 'bed',
          type: 'upgrade',
          guest: 1,
          note: '',
          amount: this.tour.details.pricing.solo,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.solo)
      } else if (this.booking.guests.length == 2 && this.booking.guests[0].preferences.room == 'solo') {
        // Separate Room
        invoice.charges.accommodation.push({
          name: 'Separate Room',
          icon: 'bed',
          type: 'upgrade',
          guest: 1,
          note: '',
          amount: this.tour.details.pricing.solo,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.solo)
      } else {
        // Shared Room
        invoice.charges.accommodation.push({
          name: 'Shared Room',
          type: 'basic',
          icon: 'bed',
          guest: 1,
          note: '',
          amount: 0,
        })
        invoice.amount.subtotal += Number(0)
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.room == 'solo') {
        invoice.charges.accommodation.push({
          name: 'Separate Room Updgrade',
          type: 'upgrade',
          icon: 'bed',
          guest: 2,
          note: '',
          amount: this.tour.details.pricing.solo,
        })
        invoice.amount.subtotal += Number(this.tour.details.pricing.solo)
      } else if (this.booking.guests.length == 2) {
        // Shared Room
        invoice.charges.accommodation.push({
          name: 'Shared Room',
          type: 'basic',
          icon: 'bed',
          guest: 2,
          note: '',
          amount: 0,
        })
        invoice.amount.subtotal += Number(0)
      }
      
      // ---------------------- 3. Vehicles ----------------------
      // Guest #1
      if (this.booking.guests[0].preferences.riding == 'bike') {
        // Vehicle
        invoice.charges.vehicles.push({
          name: this.booking.guests[0].vehicle.make + ' ' + this.booking.guests[0].vehicle.model,
          type: 'basic',
          icon: 'bike',
          guest: 1,
          note: this.booking.guests[0].vehicle.price > 0 ? 'Upgrade' : this.booking.guests[0].vehicle.price == 0 ? 'Included' : 'Credit',
          amount: this.booking.guests[0].vehicle.price,
          options: []
        })
        invoice.amount.subtotal += Number(this.booking.guests[0].vehicle.price)
        // Options
        this.booking.guests[0].vehicle.options.forEach( option => {
          if (option.selected) {
            invoice.charges.vehicles.push({
              name: option.name,
              type: 'option',
              icon: 'option',
              guest: 1,
              note: '',
              amount: option.price,
            })
            invoice.amount.subtotal += Number(option.price)
          }
        })
      } else {
        // No motorcycle
        invoice.charges.vehicles.push({
          name: 'No Bike',
          type: 'basic',
          icon: 'cancel',
          guest: 1,
          note: '',
          amount: 0,
        })
        invoice.amount.subtotal += Number(0)
      }
      // Guest #2
      if (this.booking.guests.length == 2 && this.booking.guests[1].preferences.riding == 'bike') {
        // Vehicle
        invoice.charges.vehicles.push({
          name: this.booking.guests[1].vehicle.make + ' ' + this.booking.guests[1].vehicle.model,
          type: 'basic',
          icon: 'bike',
          guest: 2,
          note: this.booking.guests[1].vehicle.price > 0 ? 'Upgrade' : this.booking.guests[1].vehicle.price == 0 ? 'Included' : 'Credit',
          amount: this.booking.guests[1].vehicle.price,
          options: []
        })
        invoice.amount.subtotal += Number(this.booking.guests[1].vehicle.price)
        // Options
        this.booking.guests[1].vehicle.options.forEach( option => {
          if (option.selected) {
            invoice.charges.vehicles.push({
              name: '+' + option.name,
              type: 'option',
              icon: 'option',
              guest: 2,
              note: '',
              amount: option.price,
            })
            invoice.amount.subtotal += Number(option.price)
          }
        })
      } else {
        // No motorcycle
        invoice.charges.vehicles.push({
          name: 'No Bike',
          type: 'basic',
          icon: 'cancel',
          guest: 2,
          note: '',
          amount: 0,
        })
        invoice.amount.subtotal += Number(0)
      }

      // ---------------------- 4. Excursion ----------------------
      // Optional excursion
      if (this.booking.excursion) {
        if (this.booking.guests.length == 1) {
          if (this.booking.guests[0].preferences.room == 'shared') {
            // 1 guest in shared room
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              icon: 'excursion',
              guest: 1,
              note: 'shared room',
              amount: Number(this.tour.excursion.pricing.base)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.base)
          } else {
            // 1 guest in solo room
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              note: 'solo room',
              icon: 'excursion',
              guest: 1,
              amount: Number(this.tour.excursion.pricing.solo)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.solo)
          }
        } else {
          if (this.booking.guests[0].preferences.room == 'shared') {
            // 2 guests in shared room
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              icon: 'excursion',
              guest: 1,
              note: 'shared room',
              amount: Number(this.tour.excursion.pricing.base)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.base)
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              icon: 'excursion',
              guest: 2,
              note: 'shared room',
              amount: Number(this.tour.excursion.pricing.base)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.base)
          } else {
            // 2 guests in solo room
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              icon: 'excursion',
              guest: 1,
              note: 'separate solo room',
              amount: Number(this.tour.excursion.pricing.solo)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.solo)
            invoice.charges.excursion.push({
              name: this.tour.excursion.description.name,
              type: 'basic',
              icon: 'excursion',
              guest: 2,
              note: 'separate solo room',
              amount: Number(this.tour.excursion.pricing.solo)
            })
            invoice.amount.subtotal += Number(this.tour.excursion.pricing.solo)
          }
        }
      }

      // ---------------------- 5. Alumni Discount ----------------------
      if (this.booking.guests[0].returning > 1) {
        invoice.charges.alumni.push({
          name: 'Alumni Discount',
          type: 'credit',
          icon: 'alumni',
          guest: 1,
          note: this.booking.guests[0].returning > 4 ? '+ tours' : ' tours',
          amount: -400 * (this.booking.guests[0].returning - 1),
        })
        invoice.amount.subtotal -= 400 * (this.booking.guests[0].returning - 1)
      }
      if (this.booking.guests.length == 2 && this.booking.guests[1].returning > 1) {
        invoice.charges.alumni.push({
          name: 'Alumni Discount',
          type: 'credit',
          icon: 'alumni',
          guest: 2,
          note: this.booking.guests[1].returning > 4 ? '+ tours' : ' tours',
          amount: -400 * (this.booking.guests[1].returning - 1),
        })
        invoice.amount.subtotal -= 400 * (this.booking.guests[1].returning - 1)
      }

      // ---------------------- 6. Tax ----------------------
      // Tax only applicable in Canada
      // if (this.booking.guests[0].address.country == 'Canada') {
      if (this.tour.template.location.countries.includes('Canada')) {
        invoice.amount.tax = invoice.amount.subtotal * 0.13
      }
      // ---------------------- 7. Total ----------------------
      invoice.amount.total = invoice.amount.subtotal + invoice.amount.tax
      // ---------------------- 8. Balance ----------------------
      invoice.balance = invoice.amount.total
      // ---------------------- 9. Return Invoice ----------------------
      return invoice
    },
    resetBooking() {
      this.booking = cloneDeep(this.bookingDefault) // Deep copy
    },
    changeGuests(to) {
      // ------------ Unchanged ------------
      if (to == this.booking.guests.length) return
      // ------------ Moving from 1 to 2 guests ------------
      // We need to add a new guest to the booking then we need to link them
      if (to == 2) {
        // 1. Add a new guest to the booking
        this.booking.guests.push(cloneDeep(this.guestDefault)) // Deep copy
        // 2. Set defaults
        this.booking.guests[1].preferences.riding = 'bike'
        // 2. Link the guests
        this.booking.guests[0].preferences.room = 'shared'
        this.booking.guests[0].preferences.roommate.type = 'known'
        this.booking.guests[0].preferences.roommate.name = 'guest #2'
        this.booking.guests[1].preferences.room = 'shared'
        this.booking.guests[1].preferences.roommate.type = 'known'
        this.booking.guests[1].preferences.roommate.name = 'guest #1'
        // 3. Set the default vehicle for the second guest
        // this.selectedVehicles[1] = ... This was already done in the init() method
        this.booking.guests[1].vehicle = cloneDeep(this.selectedVehicles[1])
        // this.selectedVehicles[1].options.forEach( option => {
        //   option.selected = false
        // })
        // 4. Billing address
        this.booking.guests[1].address.billing = false
      }
      // ------------ Moving from 2 to 1 guest ------------
      // We need to remove the second guest then we need to unlink the first guest
      if (to == 1) {
        // 1. Remove the second guest
        this.booking.guests.pop()
        // 2. Unlink the first guest
        this.booking.guests[0].preferences.roommate.type = 'unknown'
        this.booking.guests[0].preferences.roommate.name = ''
        // 3. Billing address
        this.booking.guests[0].address.billing = true
      }
      // Calculate charges
      this.calculateCharges()
    },
    // maskPhoneNumber(event, index) {
    //   let value = event.target.value;
    //   let sanitizedValue = value.replace(/[^0-9\(\)\-\s]/g, '');
    //   // Update the model with the sanitized value
    //   this.booking.guests[index].contact.formatted = sanitizedValue;
    //   // Directly updating the raw phone number
    //   this.booking.guests[index].contact.phone = sanitizedValue.replace(/\D/g, '');
    // },
    emailConfirmationRules(index) {
      return [
        this.rules.required,
        value => value === this.booking.guests[index].contact.email || 'Email addresses must match.'
      ];
    },
    validate() {
      if (this.$refs.form.validate()) { // Valid form
        this.isValidating = true
        // ------------ Copy Address ------------
        // If there are two guests and they are using the same address, we need to copy the address
        if (this.booking.guests.length == 2 && this.useSameAddress) {
          this.booking.guests[1].address.street   = cloneDeep(this.booking.guests[0].address.street)
          this.booking.guests[1].address.city     = cloneDeep(this.booking.guests[0].address.city)
          this.booking.guests[1].address.province = cloneDeep(this.booking.guests[0].address.province)
          this.booking.guests[1].address.postal   = cloneDeep(this.booking.guests[0].address.postal)
          this.booking.guests[1].address.country  = cloneDeep(this.booking.guests[0].address.country)
        }
        // ------------ Determine Invoice ------------
        let invoice = this.calculateInvoice()
        invoice.number = this.generateInvoiceNumber()
        this.booking.invoices = [invoice]
        // ------------ Determine Roommate ------------
        // Now we know the names of the guests
        if (this.booking.guests.length == 2) {
          // If the guests are sharing a room, we need to link them
          if (this.booking.guests[0].preferences.room == 'shared') {
            // this.booking.guests[0].preferences.roommate.type = 'known'
            // this.booking.guests[0].preferences.roommate.name = this.booking.guests[1].name.first + " " + this.booking.guests[1].name.last
            // this.booking.guests[1].preferences.roommate.type = 'known'
            // this.booking.guests[1].preferences.roommate.name = this.booking.guests[0].name.first + " " + this.booking.guests[0].name.last
          }
        }
        // ------------ Generate Email List ------------
        this.booking.emails = []
        this.booking.guests.forEach( guest => {
          this.booking.emails.push(guest.contact.email)
        })
        // ------------ Save Interest ------------
        // Save the booking record to the database
        this.saveInterest()
        // ------------ Generate Auth Links ------------
        this.step = 3
        this.isValidating = false
      }
    },
    saveBooking() {
      const db = getFirestore()
      const bookingRef = collection(db, 'bookings')
      if (this.booking.id == '') {
        console.log('booking', this.booking);
        // Save the booking to the database
        addDoc(bookingRef, this.booking).then((docRef) => {
          // console.log('Document written with ID: ', docRef.id)
          this.booking.id = docRef.id
        }).catch((error) => {
          console.error('Error adding document: ', error)
        })
      } else {
        // Update the booking in the database
        const docRef = doc(bookingRef, this.booking.id)
        setDoc(docRef, this.booking).then(() => {
          // console.log('Document updated with ID: ', this.booking.id)
        }).catch((error) => {
          console.error('Error updating document: ', error)
        })
      }
    },
    saveInterest() {
      const db = getFirestore()
      const interestRef = collection(db, 'interest')
      // We should flatten out the charges to get rid of quantity
      if (this.interestId == '') {  
        // Save the booking to the database
        addDoc(interestRef, this.booking).then((docRef) => {
          this.interestId = docRef.id
        }).catch((error) => {
          console.error('Error adding document: ', error)
        })
      }
    },
    sendEnquiry() {
      if (this.enquiry.status == 'sent') return
      this.enquiry.status = 'sending'
      if (!this.enquiry.name) this.enquiry.status = 'error'
      if (!this.enquiry.email && this.enquiry.preference == 'email') this.enquiry.status = 'error'
      if (!this.enquiry.phone && this.enquiry.preference == 'phone') this.enquiry.status = 'error'
      if (!this.enquiry.message) this.enquiry.status = 'error'
      // ------------ Save Enquiry ------------
      // Save the enquiry to the database
      const db = getFirestore()
      const enquiryRef = collection(db, 'enquiries')
      addDoc(enquiryRef, this.enquiry).then((docRef) => {
        // ------ Update Status ------
        this.enquiry.status = 'saved'
        // ------ Send the email ------
        // Send Enquiry
        let fields = {
          name: this.enquiry.name,
          preference: this.enquiry.preference,
          email: this.enquiry.email,
          phone: this.enquiry.phone,
          message: this.enquiry.message
        }
        let emailSchedulerEndpoint = this.production() ?
            'https://northamerica-northeast1-renedian-production.cloudfunctions.net/emailScheduler' :
            'https://northamerica-northeast1-renedian-development.cloudfunctions.net/emailScheduler';
        axios.post(emailSchedulerEndpoint, {
          to: 'info@renedian.com',
          bcc: 'webmaster@renedian.com',
          from: 'info@renedian.com',
          template: 'enquiry',
          fields: fields,
          schedule: 'now',
          status: 'pending',
        }).then((response) => {
          this.enquiry.status = 'sent'
          // this.inquiryDialog = false
          console.log('Enquiry Sent: OK')
        }).catch((error) => {
          this.enquiry.status = 'error'
          console.log('Error sending enquiry: ', error)
        });
      }).catch((error) => {
        console.error('Error adding document: ', error)
      })
    },
    async processPayment(amount, description) {
      try {
        let endpoint = this.production() ? 'https://northamerica-northeast1-renedian-production.cloudfunctions.net/processPayment' : 'https://northamerica-northeast1-renedian-development.cloudfunctions.net/processPayment'
        const response = await axios.post(endpoint, {
          token: this.payment.token.id,
          amount: amount * 100, // convert to cents
          description: description
        });
        return response.data
      } catch (error) {
        console.error('Payment processing error:', error);
        return false
      }
    },
    countryChanged(index) {
      if (this.booking.guests.length == 2) {
        if (index == 0) {
          if (this.useSameAddress) {
            this.booking.guests[1].address.country = this.booking.guests[0].address.country
          }
        } else if (index == 1) {
          if (this.useSameAddress) {
            this.booking.guests[0].address.country = this.booking.guests[1].address.country
          }
        }
      }
      // Change the phone number area code


    },
    bookTrip() {
      // ------------ Verify Token ------------
      if (!this.payment.token) {
        this.paymentError = 'Please verify your payment information and try again'
        return
      }
      // ------------ Initialize ------------
      this.processingPayment = true
      this.paymentError = ''
      // ------------ Process Payment ------------
      let depositAmount = Math.round(this.booking.invoices[0].amount.total*0.1,2)
      this.processPayment(depositAmount, this.booking.invoices[0].tour).then( result => {
        if (result) {
          console.log('Payment processed:', result)
          // ------------ Create Payment ------------
          this.booking.payments.push({
            date: DateTime.now().toISO(),
            amount: result.amount/100,
            status: result.paid ? 'paid' : 'pending',
            receipt: result.receipt_url,
            reference: result.id,
            stripe: result
          })
          // ------------ Update Invoice ------------
          this.booking.invoices[0].payments.push({
            date: DateTime.now().toISO(),
            amount: depositAmount,
            method: 'credit card',
            status: 'paid',
            reference: result.id,
          })
          this.booking.invoices[0].balance -= depositAmount
          this.booking.invoices[0].status = this.booking.invoices[0].balance == 0 ? 'paid' : 'partial'
          // ------------ Generate Invoice Number ------------
          // Random invoice number AXXXXXX-XXX
          // Random character, 6 random numbers, dash, 3 random numbers
          // ------------ Save Record ------------
          this.saveBooking()
          // ---------------------- Schedule Emails ----------------------
          // Schedule 4 confirmation emails
          // 1) Confirmation (+ Invoice document)
          // 2) Payment Receipt
          // 3) Welcome Email (aka Note from Rene)
          // 4) Internal
          let emailSchedulerEndpoint = this.production() ?
            'https://northamerica-northeast1-renedian-production.cloudfunctions.net/emailScheduler' :
            'https://northamerica-northeast1-renedian-development.cloudfunctions.net/emailScheduler';
          let billingGuest = this.booking.guests[0].address.billing ? this.booking.guests[0] : this.booking.guests[1]
          // ------------ Email #1.1: Confirmation + Invoice (Guest 1) ------------
          let fields = {
            banner_image: this.tour.template.images.email,
            confirmation_number: this.booking.invoices[0].number,
            guest1_name: this.booking.guests[0].name.first,
            guest2_name: this.booking.guests.length == 2 ? this.booking.guests[1].name.first : '',
            // Excursion
            excursion: this.booking.excursion ? this.tour.excursion.description.name : '',
            excursion_start: this.booking.excursion ? this.formatDateFull(this.tour.schedule.excursion.start) : '',
            excursion_finish: this.booking.excursion ? this.formatDateFull(this.tour.schedule.excursion.finish) : '',
            // Tour
            tour_name: this.tour.template.details.name,
            tour_start_date: this.formatDateFull(this.tour.schedule.primary.start),
            tour_finish_date: this.formatDateFull(this.tour.schedule.primary.finish),
            // Vehicles
            guest1_vehicle: this.booking.guests[0].preferences.riding == 'bike' ? this.booking.guests[0].vehicle.make + ' ' + this.booking.guests[0].vehicle.model : this.booking.guests[0].preferences.riding == 'van' ? 'Van Passenger' : 'Motorcycle Passenger',
            guest2_vehicle: this.booking.guests.length == 2 ? this.booking.guests[1].preferences.riding == 'bike' ? this.booking.guests[1].vehicle.make + ' ' + this.booking.guests[1].vehicle.model : this.booking.guests[1].preferences.riding == 'van' ? 'Van Passenger' : 'Motorcycle Passenger' : '',
            // Accommodation
            guest1_room: this.booking.guests[0].preferences.room == 'solo' ? 'Solo Room' : 'Shared Room',
            guest2_room: this.booking.guests.length == 2 ? this.booking.guests[1].preferences.room == 'solo' ? 'Solo Room' : 'Shared Room' : '',
            roommate: this.booking.guests[0].preferences.room == 'shared' ? this.booking.guests[0].preferences.roommate.name : '',
            // Portal Link
            portal_link: this.production()
              ? 'https://renedian-production.web.app/portal/login'
              : 'https://dev.renedian.com/portal/login',
            // Guest
            guest_first_name: this.booking.guests[0].name.first,
            guest_last_name: this.booking.guests[0].name.last,
            guest_address_street: this.booking.guests[0].address.street,
            guest_address_city: this.booking.guests[0].address.city,
            guest_address_province: this.booking.guests[0].address.province,
            guest_address_postal_code: this.booking.guests[0].address.postal,
            guest_address_country: this.booking.guests[0].address.country,
            guest_email: this.booking.guests[0].contact.email,
            guest_phone: this.booking.guests[0].contact.phone,
            // Billing Guest
            billing_first_name: billingGuest.name.first,
            billing_last_name: billingGuest.name.last,
            billing_address_street: billingGuest.address.street,
            billing_address_city: billingGuest.address.city,
            billing_address_province: billingGuest.address.province,
            billing_address_postal_code: billingGuest.address.postal,
            billing_address_country: billingGuest.address.country,
            billing_email: billingGuest.contact.email,
            billing_phone: billingGuest.contact.phone,
            // Invoice
            invoice_number: this.booking.invoices[0].number,
            invoice_date: this.formatDateSimple(this.booking.invoices[0].date),
            invoice_amount: this.booking.invoices[0].amount.total,
            invoice_status: this.booking.invoices[0].status,
            // 60 days before tour start date
            balance_due_date: this.getDueDate(this.tour.schedule.primary.start),
            invoice_subtotal: this.booking.invoices[0].amount.subtotal,
            invoice_tax: this.booking.invoices[0].amount.tax,
            invoice_payment: this.booking.invoices[0].payments[0].amount,
            invoice_balance: this.booking.invoices[0].balance,
            // Charges
            // We should flatten out the charges to get rid of quantity
            charges: this.booking.charges.flatMap(section =>
              section.items.map(item => ({
                section: section.name,
                amount: item.amount,
                name: item.name,
                quantity: 1
              }))
            )
          }
          axios.post(emailSchedulerEndpoint, {
            to: this.booking.guests[0].contact.email,
            bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
            from: 'info@renedian.com',
            template: 'booking-confirmation',
            fields: fields,
            schedule: '1 minutes',
            status: 'pending',
          }).then((response) => {
            this.emails.confirmation = true;
            console.log('Confirmation email: OK');
          }).catch((error) => {
            this.emails.confirmation = false;
            console.log('Confirmation email: FAIL', error);
          });
          // ------------ Email #1.2: Confirmation + Invoice (Guest 2) ------------
          if (this.booking.guests.length == 2) {
            fields.guest1_name = this.booking.guests[1].name.first
            fields.guest2_name = this.booking.guests[0].name.first
            fields.guest_first_name = this.booking.guests[1].name.first
            fields.guest_last_name = this.booking.guests[1].name.last
            fields.portal_link = this.production()
              ? 'https://renedian-production.web.app/portal/login'
              : 'https://dev.renedian.com/portal/login'
            axios.post(emailSchedulerEndpoint, {
              to: this.booking.guests[1].contact.email,
              bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
              from: 'info@renedian.com',
              template: 'booking-confirmation',
              fields: fields,
              schedule: '1 minutes',
              status: 'pending',
            }).then((response) => {
              this.emails.confirmation2 = true;
              console.log('Confirmation email: OK');
            }).catch((error) => {
              this.emails.confirmation = false;
              console.log('Confirmation email: FAIL', error);
            });
          }
          // ------------ Email #2: Receipt ------------
          fields = {
            full_date: DateTime.now().toFormat('MMMM d, yyyy'),
            amount: (result.amount/100).toFixed(2),
            receipt_link: result.receipt_url,
            portal_link: this.production()
              ? 'https://renedian-production.web.app/portal/login'
              : 'https://dev.renedian.com/portal/login'
          }
          axios.post(emailSchedulerEndpoint, {
            to: billingGuest.contact.email,
            bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
            from: 'info@renedian.com',
            template: 'receipt',
            fields: fields,
            schedule: '1 minutes',
            status: 'pending',
          }).then((response) => {
            this.emails.receipt = true
            console.log('receipt email: OK')
          }).catch((error) => {
            this.emails.receipt = false
            console.log('receipt email: FAIL', error)
          });
          // ------------ Email #3.1: Portal Link ------------
          fields = {
            portal_link: this.production()
              ? 'https://renedian-production.web.app/portal/login'
              : 'https://dev.renedian.com/portal/login',
          }
          axios.post(emailSchedulerEndpoint, {
            to: this.booking.guests[0].contact.email,
            bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
            // bcc: ['webmaster@renedian.com'],
            from: 'info@renedian.com',
            template: 'portal-link',
            fields: fields,
            schedule: '2 minutes',
            status: 'pending',
          }).then((response) => {
            this.emails.portal = true
            console.log('portal email: OK')
          }).catch((error) => {
            this.emails.portal = false
            console.log('portal email: FAIL', error)
          });
          // ------------ Email #3.2: Portal Link ------------
          if (this.booking.guests.length == 2) {
            fields = {
              portal_link: this.production()
              ? 'https://renedian-production.web.app/portal/login'
              : 'https://dev.renedian.com/portal/login',
            }
            axios.post(emailSchedulerEndpoint, {
              to: this.booking.guests[1].contact.email,
              bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
              from: 'info@renedian.com',
              template: 'portal-link',
              fields: fields,
              schedule: '2 minutes',
              status: 'pending',
            }).then((response) => {
              this.emails.portal2 = true
              console.log('portal email: OK')
            }).catch((error) => {
              this.emails.portal2 = false
              console.log('portal email: FAIL', error)
            });
          }
          // ------------ Email #4.1: Note from Rene ------------
          fields = {
            guest_name: this.booking.guests[0].name.first
          }
          axios.post(emailSchedulerEndpoint, {
            to: this.booking.guests[0].contact.email,
            bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
            from: 'rene@renedian.com',
            template: 'welcome',
            fields: fields,
            schedule: 'now',
            status: 'pending',
          }).then((response) => {
            this.emails.note = true
            console.log('welcome email: OK')
          }).catch((error) => {
            this.emails.note = false
            console.log('welcome email: FAIL', error)
          });
          // ------------ Email #4.2: Note from Rene ------------
          if (this.booking.guests.length == 2) {
            fields = {
              guest_name: this.booking.guests[1].name.first
            }
            axios.post(emailSchedulerEndpoint, {
              to: this.booking.guests[1].contact.email,
              bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
              from: 'rene@renedian.com',
              template: 'welcome',
              fields: fields,
              schedule: 'now',
              status: 'pending',
            }).then((response) => {
              this.emails.note2 = true
              console.log('welcome email: OK')
            }).catch((error) => {
              this.emails.note2 = false
              console.log('welcome email: FAIL', error)
            });
          }
          // ------------ Email #5: Internal Accounting ------------
          fields = {
            guest1_name: this.booking.guests[0].name.first + ' ' + this.booking.guests[0].name.last,
            guest2_name: this.booking.guests.length == 2 ? this.booking.guests[1].name.first + ' ' + this.booking.guests[1].name.last : '',
            email: this.booking.guests[0].contact.email,
            phone: this.booking.guests[0].contact.phone,
            address: this.booking.guests[0].address.street + ', ' + this.booking.guests[0].address.city + ', ' + this.booking.guests[0].address.province + ', ' + this.booking.guests[0].address.postal + ', ' + this.booking.guests[0].address.country,
            tour_name: this.booking.tour.name,
            tour_date: this.formatDateFull(this.booking.schedule.start),
            vehicle_1: this.booking.guests[0].vehicle.make + ' ' + this.booking.guests[0].vehicle.model,
            vehicle_2: this.booking.guests.length == 2 ? this.booking.guests[1].vehicle.make + ' ' + this.booking.guests[1].vehicle.model : '',
          }
          axios.post(emailSchedulerEndpoint, {
            to: 'info@renedian.com',
            bcc: ['webmaster@renedian.com','colette@renedian.com','piet@renedian.com'],
            from: 'info@renedian.com',
            template: 'internal-booking-confirmation',
            fields: fields,
            schedule: 'now',
            status: 'pending',
          }).then((response) => {
            this.emails.internal = true
            console.log('internal email: OK')
          }).catch((error) => {
            this.emails.internal = false
            console.log('internal email: FAIL', error)
          });
          // ------------ Delete Interest ------------
          if (this.interestId) {
            const db = getFirestore()
            const interestRef = doc(collection(db, 'interest'), this.interestId)
            deleteDoc(interestRef).then(() => {
              console.log('Interest deleted')
            }).catch((error) => {
              console.error('Error deleting interest: ', error)
            })
          }
        } else {
          console.error('Payment processing error: Stripe')
          this.paymentError = 'Payment processing error: Stripe'
          this.processingPayment = false
        }
      })
    },
    generateInvoiceNumber() {
      const randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
      const randomNumbers = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
      // const randomNumbersAfterDash = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
      return 'R' + randomChar + randomNumbers // + '-' + randomNumbersAfterDash;
    },
    setBillingAddress(index) {
      this.booking.guests[index].address.billing = true
      index = index == 0 ? 1 : 0
      this.booking.guests[index].address.billing = false
    },
    getDueDate() {
      // 60 days before the start of the tour
      const due = DateTime.fromISO(this.booking.schedule.start).minus({ days: 60 }).toFormat('MMMM d, yyyy')
      // Ensure it isn't after today
      return DateTime.fromISO(due) > DateTime.now() ? due : DateTime.now().toFormat('MMMM d, yyyy')
    },
    loadCanadaPost() {
      const script = document.createElement('script');
      script.src = 'https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.50.min.js?key=mu45-hn87-ry88-bm96';
      script.onload = () => {
        console.log('Canada Post script loaded');
        this.initializeCanadaPost(); // Only call now
      };
      script.onerror = (error) => {
        console.error('Failed to load Canada Post AddressComplete:', error);
      };
      document.head.appendChild(script);
    },
    initializeCanadaPost() {
      if (!window.addressComplete) {
        console.error('AddressComplete not available yet');
        return;
      }
      addressComplete.listen("#CanadaPostGuest1Address", {
        key: "mu45-hn87-ry88-bm96",
        maxResults: 10,
        onResult: (items) => {
          console.log("Address results:", items);
        },
        onSelect: (address) => {
          console.log("Selected address:", address);
          // this.booking.guests[0].address.street = address.Line1 || '';
          // this.booking.guests[0].address.city = address.City || '';
          // this.booking.guests[0].address.province = address.ProvinceCode || '';
          // this.booking.guests[0].address.postal = address.PostalCode || '';
          // this.CanadaPostGuest1Address = address.Line1 || '';
        },
        onError: (error) => {
          console.error("AddressComplete error:", error);
        }
      });
    },
    onSelectAddress(addressItem) {
      console.log('okay', addressItem)
      if (!addressItem || !addressItem.Id) return

      // Retrieve full address from Canada Post
      axios.get('https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3ex.ws', {
        params: {
          Key: this.canadaPostApiKey,
          Id: addressItem.Id,
        },
      })
      .then(response => {
        const data = response.data
        if (data.Items && data.Items.length > 0) {
          const fullAddress = data.Items[0]
          // Update fields in booking with retrieved address details
          this.booking.guests[0].address.street  = fullAddress.Line1 || ''
          this.booking.guests[0].address.city    = fullAddress.City || ''
          this.booking.guests[0].address.province= fullAddress.ProvinceCode || ''
          this.booking.guests[0].address.postal  = fullAddress.PostalCode || ''
        }
      })
      .catch(err => {
        console.error('Error retrieving address details:', err)
      })
    },
    resetCard() {
      this.payment.token = null;
      this.paymentCardStatus = 0
    },
    verifyCard() {
      
      // Check if the card is valid
      if (this.payment.token) {
        this.paymentCardStatus = 2;
        return
      }
      this.paymentCardStatus = 1;
      // Wait 2 seconds for verification
      setTimeout(() => {
        this.paymentCardStatus = this.payment.token ? 2 : 0;
      }, 2000);
    },
    tokenInput() {
      this.paymentCardStatus = 2
    }
  },
  watch: {
    $route(oldRoute, newRoute) {
      this.init()
    },
    selectedTour() {
      this.tour = null
      this.selectedTourId = null
    },
    selectedTourId(tourId) {
      this.resetBooking()
      this.tours.forEach( tour => {
        if (tour.id == tourId) {
          this.tour = tour
          this.loaded('tour')
        }
      })
    },
    payment: {
      deep: true,
      handler(newVal) {
        console.log('payment changed:', newVal.token);
      }
    },
    emails: {
      handler(newVal, oldVal) {
        // Check if all equal true
        if (Object.values(newVal).every(value => value === true)) {
          this.step = 4
        }
      },
      deep: true
    },
    step: {
      // Scroll to top of window
      handler() {
        window.scrollTo(0, 0)
      }  
    },
    useSameAddress: {
      handler() {
        this.countryChanged()
      }
    },
    payment() {
      console.log('token delta')
      if (this.payment.token) {
        console.log('token changed to:', this.payment.token)
      }
    },
    // Whenever the user types in the autocomplete, this searchQuery updates.
    // We debounce or simply fetch results when it’s at least 3 chars.
    searchQuery(newVal) {
      if (!newVal || newVal.length < 3) {
        this.addressSuggestions = []
        return
      }

      this.addressLoading = true
      axios.get('https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws', {
        params: {
          Key: this.canadaPostApiKey,
          SearchTerm: newVal,
          LanguagePreference: 'EN',
          MaxSuggestions: 10,
          Country: this.booking.guests[0].address.country === 'United States' ? 'USA' : 'CAN',
        },
      })
      .then(response => {
        // console.log(response)
        const data = response.data

        // For each item, create new field formatedName: Text + " " + Description
        data.Items.forEach(item => {
          item.formattedName = item.Text + ', ' + item.Description
        })

        this.addressSuggestions = data.Items ? data.Items : []
      })
      .catch(err => {
        console.error('Error fetching address suggestions:', err)
      })
      .finally(() => {
        this.addressLoading = false
      })
    },
  },
  computed: {
    sortedTours() {
      return this.availableTours.map(tour => {
        return {
          ...tour,
          dates: [...tour.dates].sort((a, b) => a.unix - b.unix)
        };
      });
    },
    computedVehicles() {
      let vehicles = []
      this.tour.vehicles.forEach( vehicle => {
        let availableOptions = []
        vehicle.options.forEach( (option, index) => {
          availableOptions.push({
            id: index,
            name: option.name,
            price: option.price,
            selected: false,
          })
        })
        let simpleVehicle = {
          id: vehicle.id,
          make: vehicle.make,
          model: vehicle.model,
          price: vehicle.rate.supplement,
          thumbnail: vehicle.images.thumbnail,
          options: availableOptions,
          order: vehicle.order,
        }
        vehicles.push(simpleVehicle)
      })
      vehicles.sort((a, b) => a.order - b.order)
      return vehicles
    },
    computedSubtotal() {
      let subtotal = 0
      this.booking.charges.forEach( charge => {
        charge.items.forEach( item => {
          subtotal += item.quantity * parseFloat(item.amount)
        })
      })
      return subtotal
    },
    computedCountries() {
      let countries = []
      this.countries.forEach( country => {
        countries.push(country.country)
      })
      // Just add a second United States to the top
      countries.unshift('Canada')
      countries.unshift('United States')
      countries.unshift('United Kingdom')
      countries.unshift('Australia')
      return countries
    },
    stripeKey() {
      return process.env.VUE_APP_STRIPE_API_PUBLIC_KEY
    },
    phoneCountryGuest1() {
      const name = this.booking.guests[0].address.country;
      // find the matching item
      const found = this.countries.find((c) => c.country === name);
      // return its code or fallback
      return found ? found.countryCode : 'CA';
    },
    phoneCountryGuest2() {
      if (this.booking.guests.length == 2) {
        const name = this.booking.guests[1].address.country;
        // find the matching item
        const found = this.countries.find((c) => c.country === name);
        // return its code or fallback
        return found ? found.countryCode : 'CA';
      }
    },
  },
  filters: {
    currency(value) {
      // return value ? 'C$' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 'C$0.00'
      // return value ? 'C$' + Number(value).toFixed(0).replace(/\d(?=(\d{3})+\b)/g, '$&,') : 'C$0'
      if (!value) return 'C$0';
      const formattedValue = Math.abs(Number(value)).toFixed(0).replace(/\d(?=(\d{3})+\b)/g, '$&,');
      return (value < 0 ? '-' : '+') + 'C$' + formattedValue;
    },
    currencySimple(value) {
      if (!value) return 'C$0';
      return 'C$' + Math.abs(Number(value)).toFixed(0).replace(/\d(?=(\d{3})+\b)/g, '$&,');
    },
    currencyRaw(value) {
      if (!value) return 'C$0';
      const formattedValue = Math.abs(Number(value)).toFixed(2).replace(/\d(?=(\d{3})+\b)/g, '$&,');
      return 'C$' + formattedValue;
    },
    miles(value) {
      return Math.round(value * 0.621371)
    }
  }
}
</script>
<style lang="scss">
  $squares: #f7941e;
  $failure: #cd2129;
  $success: #7ac142;
  .v-stepper__wrapper {
    padding-bottom: 5px !important;
  }
  .booking-container {
    padding: 0;
  }
  @media (min-width: 960px) {
    .booking-container {
      padding: 12px; /* Adjust this value to the desired padding */
    }
  }
  .step-header {
    margin-bottom: 70px;
    .step-title {
      font-size: 10px;
      font-weight: bold;
      height: 15px
    }
    .step-title-spacer {
      height: 19px;
    }
    .step-icon-spacer {
      height: 48px;
      padding: 0 4px;
    }
    .step-header-steps {
      max-width: 400px;
      margin: 0 auto;
    }
    .step-divider {
      border: 1px solid #e5e4e1;
      width: 100%;
      border-radius: 2px;
    }
    .step-description {
      text-transform: uppercase;
      position: relative;
      width: 48px;
      margin: 0 auto;
      overflow: visible;
      text-align: center;
      font-size: 10px;
      .step-description-inner {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }
  }
  .tour-banner {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbanners%2FMTN%20background%20-%20beige.svg?alt=media&token=004e40c9-5ce2-49b8-9879-24e4a4342d94');
    height: 200px;
  }
  .floating-price-footer {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbanners%2FMTN%20background%20-%20beige.svg?alt=media&token=004e40c9-5ce2-49b8-9879-24e4a4342d94');
    width: 100%;
    height: 200px;
    position: fixed;
    bottom: 0;
  }
  .ready-box {
    border: 2px solid #d8652a;
  }
  .badge-container {
    position: relative;
  }
  .badge {
    width: 200px;
    position: absolute;
  }
  .banner {
    width: 100%;
    opacity: 0.5;
  }
  .banner-container {
    position: relative;
    .badge {
      width: 125px;
    }
  }
  .tour-selection {
    max-width: 600px;
  }
  @media (min-width: 960px) { /* Adjust the value according to your breakpoints */
    .tour-preference-container {
      border: 2px solid #f4f0e3;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  .balance-summary-container {
    color: #6e6963;
    background: #f4f0e3;
    width: 300px;
  }
  .balance-summary-tear {
    background: url(https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbackground-side-tear-narrow.svg?alt=media&token=71a516ca-f689-4706-8381-b20553923029);
    background-repeat: repeat-y;
    width: 20px;
    margin-left: 50px;
  }
  .balance-summary-tear-big {
    background: url(https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2Fbackground-side-tear-narrow.svg?alt=media&token=71a516ca-f689-4706-8381-b20553923029);
    background-repeat: repeat-y;
    width: 20px;
  }
  .banner-container {
    position: relative;
    height: 200px;
    background-size: 100% 100px;
    background-repeat: no-repeat;
    background-position: center;
    .banner-badge {
      height: 200px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
      position: absolute;
      left: -120px;
    }
  }
  .orange-divider {
    border-bottom: 2px solid #d8652a;
  }
  .orange-container {
    border: 2px solid #d8652a;
    /* background: #f4f0e338; */
    border-radius: 20px;
    overflow: hidden;
  }
  .guest-title {
    background: #d8652a;
    color: white;
    padding-top: 20px;
  }
  .duration-container {
    position: relative;
    .duration-value {
      position: absolute;
      top: 23px;
      font-family: 'Renedian';
      font-size: 40px;
      color: #43413c;
    }
  }
  .paved-text {
    color: #534c45;
    font-size: 20px;
    font-weight: bold;
  }
  .gravel-text {
    color: #b19b80;
    font-size: 20px;
    font-weight: bold;
  }
  .difficulty {
    .difficulty-title {
      text-transform: uppercase;
      font-size: 12px;
    }
    .difficulty-description {
      font-size: 12px;
    }
  }
  .price-tag {
    .price-tag-tab {
      background-image: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbooking%2FPricetag-edge.svg?alt=media&token=f2c9b983-6448-4bc7-8693-729e768236a7');
      background-size: cover;
      height: 100px;
      width: 42px;
    }
    .price-tag-body {
      background: #d86225;
      width: 250px;
      height: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-right: 28px;
      .price-tag-subtotal {
        color: #8e2a17;
      }
      .price-tag-total {
        color: white;
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
  .charge {
    text-align: right;
    .charge-description {
      font-size: 14px;
    }
    .charge-option {
      border-right: 2px solid #e4e1d4;
      padding-right: 20px;
      font-size: 12px;
      .charge-description {
        font-size: 12px;
      }
    }
  }
  
  .total {
    font-size: 26px;
    text-align: center;
  }
  .mobile-banner {
    background: #f4f0e3;
    background-repeat: repeat-x;
    color: #6e6963;
  }
  .mobile-badge {
    width: 40%;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  }
  .tablet-banner {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbanners%2FMTN%20background%20-%20beige.svg?alt=media&token=004e40c9-5ce2-49b8-9879-24e4a4342d94');
    background-repeat: repeat-x;
    padding: 50px 60px;
    color: #6e6963;
  }
  .tablet-badge {
    width: 160px;
    margin-right: 60px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  }
  .v-stepper__wrapper {
    padding-bottom: 0 !important;
  }
  .mobile-summary {
    color: #6e6963;
    .mobile-summary-tear {
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbanners%2FMTN%20background%20-%20beige.svg?alt=media&token=004e40c9-5ce2-49b8-9879-24e4a4342d94');
      height: 50px;
      background-size: cover;
    }
  }
  .excursion-dialog-photo {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/photos%2Fitinerary%2Fwaterfalls-and-wildlife-excursion-photo.jpg?alt=media&token=8437a907-1b0d-4f16-b96e-2237e2cb8275');
    width: 300px;
    min-width: 300px;
    background-size: cover;
  }
  .grey-bar {
    border-bottom: 3px solid #e4e1d4;
    width: 75%;
    margin-right: 0;
    margin-left: auto;
  }
  .the-thin-grey-line {
    border-bottom: 2px solid #e4e1d4;
  }
  .the-thin-grey-line-top {
    border-top: 2px solid #e4e1d4;
  }
  .the-thin-grey-line-box {
    border: 3px solid #e4e1d4;
  }
  @for $i from 10 through 30 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}
.summary-option {
  border-left: 2px solid #98918a;
  padding-left: 10px;
}
.field-container {
  max-width: 400px;
}
.absolute-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
/* ---------- Folding Cubes ---------- */
.sk-folding-cube {
	margin: 20px auto;
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
}
.sk-folding-cube .sk-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $squares;
	-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
	animation: sk-foldCubeAngle 2.4s infinite linear both;
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
	-webkit-transform: scale(1.1) rotateZ(90deg);
	transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
	-webkit-transform: scale(1.1) rotateZ(180deg);
	transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
	-webkit-transform: scale(1.1) rotateZ(270deg);
	transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
	0%, 10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
	    transform: perspective(140px) rotateX(-180deg);
	    opacity: 0;
	} 25%, 75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	} 90%, 100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}
@keyframes sk-foldCubeAngle {
	0%, 10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	} 25%, 75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	} 90%, 100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}
/*-- ---------------------- Checkmark ---------------------- --*/
.checkmark {
  width: 20%;
  max-width: 110px;
  min-width: 100px;
}
.cls-1 {
	fill: none;
	stroke: $success;
	stroke-miterlimit: 10;
	stroke-width: 5px;
}

#checkmark-svg {
	height: 100%;
	max-height: 100%;
	.cls-1 {
		stroke: #7ac142;
	}
}

#cross-svg {
	height: 100%;
	max-height: 100%;
	.cls-1 {
		stroke: #fff;
	}
}

.circle {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
}

.checkmark {
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
}

.run-animation {  
  .circle {
    animation: 2.5s circleDraw forwards;
  }

  .checkmark {
    animation: 0.75s checkmarkDraw forwards;
    animation-delay: 1s;
  }
}

@keyframes circleDraw {
  from {
    stroke-dashoffset: 700;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmarkDraw {
  from {
    stroke-dashoffset: 150;
  }
  to {
    stroke-dashoffset: 0;
  }
}

/*-- ---------------------- Failure Cross ---------------------- --*/
.cls-2 {
	fill: none;
	stroke: $failure;
	stroke-miterlimit: 10;
	stroke-width: 5px;
}
.cross-svg {
	height: 100%;
	max-height: 100%;
}
.cross-svg polyline {
	stroke-dasharray: 150;
	stroke-dashoffset: 150;
}
.run-animation2 {  
	circle {
		animation: 2.5s circleDraw forwards;
	}
	polyline {
		animation: 0.75s crossDraw forwards;
		animation-delay: 1s;
	}
}
@keyframes circleDraw {
	from {
		stroke-dashoffset: 700;
	}
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes crossDraw {
	from {
		stroke-dashoffset: 150;
	}
	to {
		stroke-dashoffset: 0;
	}
}
/* ---------- Payment ---------- */
.success-title {
  font-size: 24px; 
}
.success-description {
}
/* ---------- Phone ---------- */
.v-phone-input__country {
  max-width: 80px;
}

.billing-address-container {
  border: 3px solid white;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
}
.billing-address-container.active {
  border: 3px solid #e4e1d4;
}
.glow-button {
  transition: all 0.3s ease;
  box-shadow: 0 0 1px #f26522, 0 0 1px #f26522, 0 0 1px #f26522, 0 0 5px #f26522;
}
.glow-button:hover {
  box-shadow: 0 0 1px #f26522, 0 0 1px #f26522, 0 0 1px #f26522, 0 0 10px #f26522;
}
.booking-confirmation-ul {
  width: 75%;
  li {
    text-align: left;
  }
}
</style>